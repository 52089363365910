$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-path: '../../../../fonts';
$info:  #0079ff;
$lead-color: #00aeff;
$title-color: #4285ea;
$enable-responsive-font-sizes: true;
$enable-negative-margins: true;
$cloudinary-path: 'https://res.cloudinary.com/maunawai/image/upload/';
$input-btn-padding-x:         1rem;
$enable-gradients: true;
$enable-shadows: true;
$enable-smooth-scroll: true;
$headings-color: #fff;

// scss-docs-start grid-breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  4k: 2560px
) !default;
// scss-docs-end grid-breakpoints

$lead-font-size: $font-size-base * 1.5;

$blue-1000: hsl(205, 100%, 21%);
$blue-900: hsl(205, 87%, 29%);
$blue-800: hsl(205, 82%, 33%);
$blue-700: hsl(205, 76%, 39%);
$blue-600: hsl(205, 67%, 45%);
$blue-500: hsl(205, 65%, 55%);
$blue-400: hsl(205, 74%, 65%);
$blue-300: hsl(205, 84%, 74%);
$blue-200: hsl(205, 97%, 85%);
$blue-100: hsl(205, 79%, 92%);

$blues: () !default;
$blues: map-merge(
                (
                        "100": $blue-100,
                        "200": $blue-200,
                        "300": $blue-300,
                        "400": $blue-400,
                        "500": $blue-500,
                        "600": $blue-600,
                        "700": $blue-700,
                        "800": $blue-800,
                        "900": $blue-900,
                        "1000": $blue-1000
                ),
                $blues
);

$blue2-1000: hsl(225,100%,18%);
$blue2-900: hsl(221,100%,20%);
$blue2-800: hsl(221,78%,38%);
$blue2-700: #4386EA;
$blue2-600: #4386EA;
$blue2-500: #4386EA;
$blue2-400: hsl(196, 97%, 51%);
$blue2-300: hsl(195,100%,70%);
$blue2-200: hsl(194,90%,85%);
$blue2-100: hsl(194,100%,90%);

$blue2s: () !default;
$blue2s: map-merge(
                (
                        "100": $blue2-100,
                        "200": $blue2-200,
                        "300": $blue2-300,
                        "400": $blue2-400,
                        "500": $blue2-500,
                        "600": $blue2-600,
                        "700": $blue2-700,
                        "800": $blue2-800,
                        "900": $blue2-900,
                        "1000": $blue2-1000
                ),
                $blue2s
);

$yellow-1000: hsl(15, 86%, 30%);
$yellow-900: hsl(22, 82%, 39%);
$yellow-800: hsl(29, 80%, 44%);
$yellow-700: hsl(36, 77%, 49%);
$yellow-600: hsl(42, 87%, 55%);
$yellow-500: hsl(44, 92%, 63%);
$yellow-400: hsl(48, 94%, 68%);
$yellow-300: hsl(48, 95%, 76%);
$yellow-200: hsl(48, 100%, 88%);
$yellow-100: hsl(49, 100%, 96%);

$yellows: () !default;
$yellows: map-merge(
                (
                        "100": $yellow-100,
                        "200": $yellow-200,
                        "300": $yellow-300,
                        "400": $yellow-400,
                        "500": $yellow-500,
                        "600": $yellow-600,
                        "700": $yellow-700,
                        "800": $yellow-800,
                        "900": $yellow-900,
                        "1000": $yellow-1000
                ),
                $yellows
);


$lime-1000: hsl(100, 93%, 17%);
$lime-900: hsl(99, 91%, 25%);
$lime-800: hsl(100, 89%, 31%);
$lime-700: hsl(92, 89%, 38%);
$lime-600: hsl(92, 86%, 45%);
$lime-500: hsl(90, 84%, 55%);
$lime-400: hsl(88, 91%, 66%);
$lime-300: hsl(86, 100%, 76%);
$lime-200: hsl(83, 100%, 87%);
$lime-100: hsl(83, 100%, 96%);

$limes: () !default;
$limes: map-merge(
                (
                        "100": $lime-100,
                        "200": $lime-200,
                        "300": $lime-300,
                        "400": $lime-400,
                        "500": $lime-500,
                        "600": $lime-600,
                        "700": $lime-700,
                        "800": $lime-800,
                        "900": $lime-900,
                        "1000": $lime-1000
                ),
                $limes
);

$grey-1000: hsl(210, 24%, 16%);
$grey-900: hsl(209, 20%, 25%);
$grey-800: hsl(209, 18%, 30%);
$grey-700: hsl(209, 14%, 37%);
$grey-600: hsl(211, 12%, 43%);
$grey-500: hsl(211, 10%, 53%);
$grey-400: hsl(211, 13%, 65%);
$grey-300: hsl(210, 16%, 82%);
$grey-200: hsl(214, 15%, 91%);
$grey-100: hsl(216, 33%, 97%);

$greys: () !default;
$greys: map-merge(
                (
                        "100": $grey-100,
                        "200": $grey-200,
                        "300": $grey-300,
                        "400": $grey-400,
                        "500": $grey-500,
                        "600": $grey-600,
                        "700": $grey-700,
                        "800": $grey-800,
                        "900": $grey-900,
                        "1000": $grey-1000
                ),
                $greys
);

$maunawai-light-blue:$blue2-400;
$maunawai-dark-blue: $blue2-600;
$maunawai-yellow: hsl(56, 59%, 56%);
$maunawai-green:  hsl(154, 45%, 45%);

$maunawai-black: hsl(0, 0%, 24%);

$blue-gradient-start: $maunawai-light-blue;
$blue-gradient-stop: $maunawai-dark-blue;

$btn-border-radius: 0.2rem;
$btn-border-radius-lg: 0.2rem;
$input-border-radius: 0.2rem;

$slick-arrow-color: $maunawai-black;
$green:       $lime-700;
$btn-link-color: $grey-500;