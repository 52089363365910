@mixin side-img($side, $top: top) {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: $top $side;
}

@mixin special-text($start, $stop) {
  background: linear-gradient(to right, $start, $stop);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin app-box-shadow($number) {
  @if($number ==1) {
  box-shadow: 0 0.0625rem 0.188rem rgba(0, 0, 0, .12), 0 0.0625rem 0.125rem rgba(0, 0, 0, .24);
  } @else if($number == 2) {
    box-shadow: 0 0.188rem  0.375rem rgba(0,0,0,.15), 0 0.125rem 0.25rem rgba(0,0,0,.12);
  } @else if($number == 3) {
    box-shadow: 0 0.625rem  1.25rem rgba(0,0,0,.15), 0 0.188rem 0.375rem rgba(0,0,0,.10);
  } @else if($number == 4) {
    box-shadow: 0 0.9375rem  1.5625rem rgba(0,0,0,.15), 0 0.3125rem 0.625rem rgba(0,0,0,.15);
  } @else if($number == 5) {
    box-shadow: 0 1.25rem  2.5rem rgba(0,0,0,.2);
  }
}


@mixin cloudinary_bg_img($path, $file,$with_xs: true, $breakpoints: $grid-breakpoints) {
  @each $name, $size in $breakpoints {

      @include media-breakpoint-up($name) {
        @if($name == 'xs') {
          @if($with_xs) {
            $param: 'f_auto,q_auto,w_576/';
            $img_path: $cloudinary-path  + $param + $path;
            background-image: url("#{$img_path}/#{$file}");
          }
        }
        @else {
          $param: 'f_auto,q_auto,w_' + calc($size/1px) + '/';
          $img_path: $cloudinary-path  + $param + $path;
          background-image: url("#{$img_path}/#{$file}");
        }
      }

  }
}
