*{
  font-family: 'Anton', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Anton', sans-serif;
    position: relative;
 }
.top-300{
 top: -300px;
}
 header {
   height: 70px;
   background-image: linear-gradient(45deg, hsl(196deg, 97%, 51%), rgba(67, 134, 234, 1));
   svg {
        width: 1em;
    }

   .logo img{
     max-width: 120px;
   }
 }
 a {
   text-decoration: none !important;
   color: #4386ea;
   &.white-link{
     color: white;
     text-decoration: underline !important;
   }
 }
 .text-nowrap{
   white-space: nowrap;
 }
 .z-index-10{
   z-index: 10;
 }

 .slick-prev::before {
   content: '\f053' !important;
   font-weight: 600 !important;
   font-family: "Font Awesome 5 Free" !important;
   font-size: 1.563rem !important;
   display: none;
 }
 .slick-next::before {
   content: '\f054' !important;
   font-weight: 600 !important;
   font-family: "Font Awesome 5 Free" !important;
   font-size: 1.563rem !important;
   display: none;
   opacity: 0;
 }

 .slick-prev::before, .slick-next::before {
   color: hsla(204, 90%, 55%, 1) !important ;
 }

//  .container {
//    max-width: 1300px;
//  }
 .header_section {
   height: 100%;
 }


 footer{
  margin-top: 80px;
    .bottom_text{
        max-width: 340px;
        margin-top: 46px;
        a{
          font-family: 'Roboto', sans-serif;
          font-weight: 500;
        }
    }
    .about_us_container{
      width: 100%;
      max-width: 900px;
      padding-left: 70px;
      padding-right: 155px;
      position: relative;
      .logo{
        width: 122px;
        position: absolute;
        right: 0;
        bottom: 0;
      }
      h4,.h4{
        color: #666666;
        margin-bottom: 24px;
        font-weight: bold;
        width: 100%;
        text-align: left;
      }
      p{
        text-align: left;
        font-size: 16px;
        margin-bottom: 0;
        color: #000;
      }
    }
    @media(max-width: 768px){
      .footer-bg-image{
        padding-top: 20px !important;
        background-size: cover !important;
      }
      .about_us_container .logo{
        display: none;
      }
      .about_us_container{
        padding-left: 0;
        padding-right: 0;
        h4,.h4{
          color: #666666;
          font-size: 28px;
          -webkit-text-fill-color:initial;
          background: none;
        }
      }
    }
 }

 .bg-color {
   background-color: hsla(214, 24%, 94%, 1);
 }

 .water-mark {
   opacity: 0.3;
 }

 .row.types-width {
   max-width: 75%;
   margin: auto;
   padding: 3.125rem 0;
 }

 .types-bottle h4 {
   color: hsla(216, 80%, 59%, 1);
   font-size: 1.25rem;
   font-family: 'Roboto', sans-serif;
   font-weight: 400;
   line-height: 1.75rem;
   margin-bottom: 0.313rem;
 }

 .types-bottle p{
   max-width: 100%;
   margin-bottom: 1.313rem;
   margin-left: initial;
   position: relative;
 }

 .position-absolute-desktop{
   @include media-breakpoint-up(lg) {
     position: absolute;
   }
 }


 footer .footer-bg-image{
   @include cloudinary_bg_img('blog-resources/blog', 'footer_bg.png', true);
   background-size: 100% 100%;
   background-repeat: no-repeat;
   padding: 6.25rem 0;
   padding-top: 97px;
   padding-bottom: 170px;
  //  padding-bottom: 40px;
 }

 footer .list-inline{
  .list-inline-item{
    margin-right: 20px;
  }
  svg{
    width: 1rem;
  }
 }

 footer{
  .header_inner{
    padding: 23px 0;
  }
 }

 .footer-bottom .logo a img {
   width: 90px;
 }

 .leaf-image-right {
   right: 0;
   top: 100px;
   @media (max-width: 1199px){
    right: -100px;
   }
 }
 .leaf-image-right-top {
   top:-70px;
 }

 .leaf-image-right-bottom img {
   height: 650px;
 }

 .leaf-image-right img {
   height: 650px;
 }

 .footer-bottom {
   background-image: linear-gradient(45deg, hsl(196deg, 97%, 51%), rgb(67, 134, 234));
 }

 .eco-content img {
   width: 50px;
 }

 .footer-content .eco-content h4 {
   font-size: 1.75rem;
   width: 21%;
   margin: auto;
   color: hsla(0, 0%, 40%, 1);
   padding: 0.625rem 0 1.563rem;
   line-height: 2rem;
 }

 .footer-content .eco-content h4 span {
   color: #4386EA;
 }


 .footer-bottle-image {
   right: 95px;
   top: -70px;
   width: 30%;
   position: absolute;
 }

 .border-bg {
   width: 12%;
   height: 10px;
   z-index: 0;
   background-image: linear-gradient(90deg, hsla(196, 97%, 51%, 1) 0, hsla(215, 80%, 60%, 1));
   margin: 0 auto -1.875rem;
 }

 .slick-active.slick-current .banner_section {
   padding-right: 0.625rem;
 }

 @media (min-width: 768px) and (max-width: 991px) {
   .footer-content .eco-content h4 {
     width: 50%;
   }
 }

 @media (max-width: 767px) {
   .footer-content .eco-content h4 {
     width: 100%;
   }

   footer .footer-bg-image {
     padding: 0 0 5.625rem 0;
     background-position: bottom;
     background-size: cover;
   }
   .footer-bottle-image {
     position: unset;
     margin: auto;
     width: 50%;
   }
 }

 @media(max-width: 991px) {

   .footer-bottle-image{
     right: 0px;
   }

 }
 @media (max-width: 420px) {
   .banner_section .corner{
     width: 9.375rem;
     height: 9.375rem;
   }
 }



main{
  overflow-x: hidden;
}



//Header with left logo
.left-logo-header{
 .header_inner {
   color: 	hsl(0, 0%, 100%);
   padding: 22px 0px;
   padding-right: 30px;
   display: flex;
   align-items: center;
 }
 .header_btn a {
   border: 1px solid 	hsl(0, 0%, 100%);
   padding: 10px 40px;
   display: inline-block;
   font-size: 12px;
   text-transform: uppercase;
   border-radius: 3px;
   margin-left: 20px;
   color: hsl(0, 0%, 100%);
   text-decoration: none;
   font-family: 'Open Sans', sans-serif;
   font-weight: 400;
   transition: background 0.3s ease-out;
 }
 .header_btn a:hover {
   background: white;
   color: hsl(216, 80%, 59%);
 }
 .header_social{
   display: block;
 }
 .header_social li {
   margin-right: 20px;
 }
 .logo_sec img {
   max-width: 100px;
   z-index: 999;
   bottom: -40px;
   left: 41px;
   border-radius: 50%;
 }
 .header_social ul {
   margin: 0;
 }
 .navbar{
  //  background: 	hsl(204, 90%, 55%) !important;
   padding-top: 0px;
   padding-bottom: 0px;
   background-image: none;
 }
}
@media (max-width:767px) {
 body.blog .left-logo-header {
   height: auto;

   .offcanvas-header{ display:block; }

   .mobile-offcanvas{
     visibility: hidden;
     transform:translateX(-100%);
     border-radius:0;
     display:block;
     position: fixed;
     top: 0; left:0;
     height: 100%;
     z-index: 1200;
     width:80%;
     overflow-y: scroll;
     overflow-x: hidden;
     transition: visibility .2s ease-in-out, transform .2s ease-in-out;
     bottom: 0;
   }
   .btn-close{
     background: none;
     opacity: 1;
     font-size: 1.188rem;
   }

   .mobile-offcanvas.show{
     visibility: visible;
     transform: translateX(0);
   }
   .navbar {
     display: block !important;
     background-color: 	hsl(204, 90%, 55%);
     position: fixed !important;
   }
   .navbar-toggler {
     padding: 26px 20px !important;
     outline: none !important;
     box-shadow: none !important;
   }
   .navbar-toggler i {
     color: 	hsl(0, 0%, 100%);
   }
   .header_social ul {
     margin-bottom: 15px;
   }
   .header_social li:last-child{
     margin-right: 0px;
   }
   .header_btn a {
     margin-left: 0px;
   }
   .header_inner {
     color: 	hsl(0, 0%, 100%);
     padding: 45px 0px 25px;
     display: block;
     text-align: center;
   }
 }
}

.shipping_free_section{
  top: 0;
  left: 0;
  right: 0;
  background: #edf0f4;
  z-index: 1;
  @media(max-width: 768px){
    display: none !important;
  }
}

.shipping_free_section p{
  margin-bottom: 0;
  color: #5b5d5c;
  font-family: Roboto;
  font-size: 15px;
  text-align: center;
  display: inline-block;
  padding: 12px 0;
  width: 100%;
  @media(max-width: 991px){
    font-size: 14px;
    padding: 6px 0;
  }
}

.shipping_free_section p a{
  color: #5b5d5c;
  font-weight: 400;
  font-family: Roboto;
  text-decoration: underline !important;
}

main{
  position: relative;
}

.single_item_section{
  padding-top: 100px;
  padding-bottom: 60px;
  .container{
    max-width: 1030px;
  }
  .slider_dots{
    display: none;
    width: 100%;
    margin-top: -80px;
    z-index: 2;
    .slick-dots{
      position: static;
      list-style: none;
      li{
        width: auto;
        height: auto;
        margin: 0 4px;
      }
      li button{
        border: 1px solid #0cb9fb;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        font-size: 0;
        text-indent: -10000;
        &::before{
          display: none;
        }
      }
      .slick-active button{
        background: #0cb9fb;
      }
    }
    @media(max-width: 768px){
      display: block;
    }
  }
  .left_cont{
    .slider_top{
      width: 100%;
      .inside{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .main_photo{
          width: 100%;
          object-fit: cover;
          max-height: 580px;
          max-width: 629px;
        }
      }
      @media(max-width: 768px){
        .inside{
          background: #f0f0f1;
          padding-bottom: 30px;
        }
      }
    }
    .slider_bottom_wrap{
      position: relative;
      margin-top: 25px;
      .slider{
        width: calc(100% - 184px);
        .slick-slide{
          margin: 0 8px;
          cursor: pointer;
        }
        .inside{
          height: 69px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #9cddfc;
          border-radius: 4px;
          img{
            max-width: 100%;
            object-fit: cover;
            max-height: 100%;
          }
        }
      }
      .arrow{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        img{
          width: 27px;
        }
        &.arrow_left{
          left: 48px;
        }
        &.arrow_right{
          right: 48px;
        }
      }
      @media(max-width: 991px){
        .slider .inside{
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          img{
            max-width: 90%;
            max-height: 90%;
          }
        }
      }
      @media(max-width: 768px){
        display: none !important;
      }
    }
  }
  // &.single_item_section2{
  //   .left_cont .slider_top .inside{
  //     .main_photo{
  //       max-height: none;
  //       max-width: 100%;
  //     }
  //   }
  // }
  .right_cont{
    padding-left: 30px;
    .bottom_filter_cartridge{
      color: #000000;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 300;
      margin-top: 20px;
    }
    .small_logo{
      width: 65px;
      margin-bottom: 4px;
      @media(max-width: 768px){
        display: none;
      }
    }
    .reviews_wrap{
      .raiting{
        margin: 0;
        padding: 0;
        list-style: none;
        margin-right: 8px;
        img{
          width: 90px;
        }
      }
      p{
        margin-bottom: 0;
        color: #4d4d4d;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 500;
        text-decoration: underline;
        @media(max-width: 768px) {
          font-weight: 400;
        }
      }
    }
    .natural_water{
      padding: 15px 0;
      padding-right: 30px;
      border-top: 1px solid #e6e6e6;
      border-bottom: 1px solid #e6e6e6;
      margin-top: 15px;
      max-width: 494px;
      .top_p{
        color: #000000;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 300;
        margin-bottom: 17px;
      }
      .cont{
        img{
          width: 70px;
        }
        p{
          width: calc(100% - 70px);
          padding-left: 17px;
          color: #000000;
          font-family: Roboto;
          font-size: 16px;
          font-weight: 300;
          line-height: 130%;
          margin-bottom: 0;
          span{
            color: #4285ea;
            font-family: Roboto;
            font-weight: 500;
          }
        }
      }
    }
    h1,.h1{
      position: relative;
      display: inline-flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      align-items: flex-start;
      margin-top: 10px;
      margin-bottom: 10px;
      span{
        display: inline-flex;
      }
      i{
        font-style: normal;
        font-size: 34px;
        line-height: 15px;
        margin-top: 12px;
      }
    }
    .code{
      margin-bottom: 0px;
      color: #4d4d4d;
      font-family: Roboto;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 400;
    }
    .taxes_wrap{
      margin-bottom: 20px;
      margin-top: 14px;
      .left_area{
        max-width: 383px;
        .taxes{
          color: #4d4d4d;
          font-family: "Open Sans";
          font-size: 10px;
          font-weight: 400;
          margin-bottom: 14px;
          text-align: left;
          span{
            color: #666666;
            font-family: Anton;
            font-size: 42px;
            text-transform: uppercase;
            margin-right: 3px;
          }
        }
        ul{
          padding: 0;
          margin: 0;
          list-style: none;
          li{
            color: #4d4d4d;
            font-family: Roboto;
            font-size: 12px;
            font-weight: 500;
            padding: 10px 16px;
            background: #edf0f4;
            border-radius: 20px;
            margin: 0 3px;
            @media(max-width: 500px) {
              margin: 3px;
            }
          }
        }
      }
      img{
        width: 128px;
      }
    }
    .bottom_area{
      .calculator{
        width: 80px;
        border: 1px solid #cbcbcb;
        border-radius: 4px;
        padding: 0 7px;
        input{
          height: 45px;
          border: none !important;
          background: none;
          padding: 0;
          width: 26px;
          text-align: center;
          color: #666666;
          font-family: "Open Sans";
          font-size: 16px;
          font-weight: 600;
          &:focus-visible{
            outline:none !important;
          }
        }
        span{
          color: #666666;
          font-family: "Open Sans";
          font-size: 17px;
          font-weight: 600;
          cursor: pointer;
          user-select: none;
          padding: 3px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
        }
      }
      .add_cart{
        width: 100%;
        max-width: 390px;
        margin-left: 13px;
        border: none !important;
        outline: none !important;
        display: inline-flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .cart_form{
    .colors_cont{
      margin-bottom: 20px;
      p{
        width: 100%;
        color: #4d4d4d;
        font-family: "Open Sans";
        font-size: 12px;
        font-weight: 400;
        font-style: normal;
        margin-bottom: 8px;
      }
      .row_area{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        .color_item{
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          max-width: 80px;
          margin-right: 14px;
          input{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            cursor: pointer;
            opacity: 0;
            font-size: 0;
            &:checked + figure{
              border: 1px solid #54cdfc;
            }
          }
          figure{
            width: 100%;
            height: 80px;
            border: 1px solid #e0e0e0;
            border-radius: 4px;
            margin: 0;
            img{
              max-width: 100%;
            }
          }
          p{
            margin-bottom: 0;
            text-align: center;
            margin-top: 8px;
          }
        }
      }
    }
  }
  @media(max-width: 1199px){
    .right_cont{
      h1,.h1{
        i{
          font-size: 27px;
          margin-top: 4px;
        }
      }
    }
  } 
  @media(max-width: 1199px){
    .right_cont .bottom_area .add_cart{
      max-width: calc(100% - 112px) !important;
      margin-left: 15px;
    }
    .right_cont .taxes_wrap .left_area ul li{
      padding: 10px;
    }
    .right_cont{
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
  @media(max-width: 991px){
    padding-top: 85px;
    padding-bottom: 40px;
    .left_cont .slider_bottom_wrap .slider{
      width: calc(100% - 100px);
    }
    .left_cont .slider_bottom_wrap .arrow.arrow_left{
      left: 0;
    }
    .left_cont .slider_bottom_wrap .arrow.arrow_right{
      right: 0;
    }
    .left_cont,.right_cont{
      width: 100%;
      // max-width: 550px;
      margin-left: auto;
      margin-right: auto;
    }
    .right_cont .code{
      margin-bottom: 15px;
    }
    .right_cont .taxes_wrap{
      margin-bottom: 15px;
      .left_area{
        max-width: calc(100% - 100px);
      }
      img{
        margin-top: 0;
        width: 100px;
      }
    }
    .bottom_area{
      margin-bottom: 0;
      justify-content: space-between !important;
      .add_cart{
        width: 100%;
        max-width: calc(100% - 105px);
        margin-left: 0;
        margin-top: 0;
        padding-left: 10px;
        padding-right: 5px;
      }
    }
  }
  @media(max-width: 768px){
    padding-top: 0;
    .right_cont{
      padding-top: 55px;
      padding-left: 25px !important;
      padding-right: 25px !important;
      .taxes_list{
        justify-content: flex-start !important;
      }
      h1,.h1{
        font-size: 45px;
        i{
          font-size: 22px;
          margin-top: 0px;
          display: none;
        }
      }
    }
    .right_cont .taxes_wrap .left_area .taxes span{
      font-size: 30px;
    }
    .left_cont .slider_top{
      margin-left: -25px;
      margin-right: -25px;
      width: calc(100% + 50px);
      .inside{
        padding-top: 80px;
        padding-bottom: 50px;
      }
    }
  }
  @media(max-width: 500px){
    .right_cont .bottom_area .add_cart{
      max-width: 400px;
    }
    .right_cont .natural_water .cont p,.right_cont .natural_water .top_p{
      font-size: 14px;
    }
  }
}

.maunawai_better{
  padding-top: 100px;
  padding-bottom: 105px;
  background: #edf0f4;
  .bottom_img{
      position: absolute;
      width: 268px;
      height: 252px;
      right: -46px;
      bottom: -76px;
      z-index: 1;
  }
  .plant_right{
    position: absolute;
    bottom: 260px;
    right: 0;
    z-index: 2;
  }
  h2,.h2{
      margin-bottom: 20px;
  }
  .text_top{
    width: 100%;
    color: #000000;
    font-family: Roboto;
    font-size: 20px;
    font-weight: 300;
    line-height: 27.99px;
    max-width: 584px;
    text-align: center;
    margin-bottom: 52px;
  }
  .columns_wrap{
      max-width: 966px;
      .column{
        max-width: 220px;
          &:first-child{
              .text{
                  max-width: 240px;
              }
          }
          &:nth-child(2){
              .text{
                  max-width: 235px;
              }
          }
          &:last-child{
              .text{
                  max-width: 240px;
              }
          }
          figure{
              height: 66px;
              margin-bottom: 27px;
              width: 100%;
          }
          p{
              text-align: center;
          }
          .title{
              color: #4285ea;
              background: linear-gradient(
              90deg,#09bbfb,#478beb);
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
              font-size: 26px;
              // font-weight: bold;
              line-height: 27.99px;
              text-transform: uppercase;
              margin-bottom: 15px;
              letter-spacing: .5px;
          }
          .text{
              font-size: 18px;
          }
          a{
            font-family: 'Roboto', sans-serif;
            font-weight: 500;
            img{
                width: 11px;
                margin-left: 6px;
            }
            @media (max-width: 350px){
              font-size: 14px;
            }
          }
      }
  }
  @media (max-width: 1400px){
    .plant_right{
      right: -50px;
      bottom: 180px;
      img{
        height: 650px;
      }
    }
  }
  @media (max-width: 991px){
    padding-top: 60px;
    padding-bottom: 60px;
    .plant_right{
      display: none;
    }
  }
  @media (max-width: 768px){
    padding-top: 40px;
    padding-bottom: 40px;
    h2,.h2{
      padding-bottom: 0;
      border: none;
      font-size: 38px;
    }
    .columns_wrap .column .title{
      font-size: 22px;
    }
    .columns_wrap{
      flex-direction: column;
      align-items: center !important;
      .column{
        max-width: 100% !important;
        width: 100%;
        margin-bottom: 20px;
        flex-direction: row !important;
        border-bottom: 1px solid #c7c7c7;
        padding-bottom: 20px;
        &:last-child{
          margin-bottom: 0;
        }
        figure{
          height: auto;
          margin-bottom: 0;
          width: 55px;
          img{
            max-width: 100%;
          }
        }
        .text{
          font-size: 16px;
          max-width: calc(100% - 65px) !important;
          padding-left: 33px;
          text-align: left !important;
          width: 100%;
        }
      }
    }
    .plant_right{
      display: block;
      width: 70px;
      right: 0;
      bottom: initial;
      top: -30px;
    }
    .text_top{
      font-size: 16px;
      line-height: 120%;
      margin-bottom: 42px;
    }
  }
}

.pi_technology{
  padding-top: 100px;
  padding-bottom: 75px;
  .top_text{
    text-align: center;
    max-width: 690px;
    margin-top: 12px;
    span{
      color: #4285ea;
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
    }
  }
  @media (max-width: 768px){
    padding: 40px 0;
    h2,.h2{
      font-size: 46px;
    }
  }
}

.natural_spring_water{
  background: #edf0f4;
  padding-top: 30px;
  padding-bottom: 30px;
  .left_img{
    width: 100%;
    max-width: 42%;
    object-fit: cover;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
  }
  .container{
    z-index: 2;
    max-width: 1138px;
  }
  .inside{
    padding-left: 300px;
    .left_cont{
      max-width: 183px;
      margin-right: 39px;
      img{
        width: 100%;
      }
    }
    .right_cont{
      width: 100%;
      max-width: 587px;
      img{
        width: 100%;
        margin-bottom: 25px;
      }
      p{
        margin-bottom: 0;
        color: #4285ea;
        font-family: Roboto;
        font-size: 18px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: normal;
        line-height: 130%;
      }
    }
  }
  &.natural_spring_water2{
    // background: none;
    // background-color: #1aacf7;
    .right_cont{
      p{
        color: #fff;
      }
    }
  }
  @media (max-width: 991px){
    .left_img{
      display: none;
    }
    .inside{
      padding-left: 0;
      justify-content: center !important;
    }
  }
  @media (max-width: 768px){
    padding: 40px 0;
    padding-top: 20px;
    .left_img{
      display: block;
      height: 300px;
      object-fit: cover;
      max-width: calc(100% - 90px);
    }
    .inside{
      padding-right: 0;
      flex-direction: column;
      align-items: flex-end !important;
      padding-left: 0;
      .left_cont{
        margin-right: 0;
        margin-bottom: 20px;
        img{
          max-width: 124px;
        }
      }
      .right_cont{
        max-width: 100%;
        padding-top: 30px;
      }
      .right_cont p{
        font-size: 16px;
        line-height: 130%;
        text-align: center;
        max-width: 90%;
      }
    }
  }
}

.technology_list_section{
  padding-top: 100px;
  padding-bottom: 138px;
  .container{
    max-width: 700px;
  }
  .row_item{
    width: 100%;
    padding: 50px 34px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // margin-bottom: 35px;
    border-bottom: 1px solid #d8d8d8;
    &:first-child{
      padding-top: 0;
    }
    &:last-child{
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: none;
    }
    figure{
      width: 56px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0;
      img{
        max-width: 100%;
        max-height: 65px;
      }
    }
    p{
      width: calc(100% - 66px);
      padding-left: 27px;
      color: #000000;
      font-size: 18px;
      font-style: normal;
      letter-spacing: normal;
      line-height: 130%;
      margin-bottom: 0;
      span{
        font-family: Roboto;
        font-weight: 500;
        color: #4285ea;
      }
    }
  }
  @media (max-width: 768px){
    padding: 40px 0;
    .row_item{
      padding: 15px 0;
      align-items: center;
      margin-bottom: 20px;
      figure{
        width: 65px;
        img{
          max-width: 100%;
        }
      }
      &:first-child{
        padding-top: 0;
      }
      p{
        width: calc(100% - 65px);
        padding-left: 30px;
        text-align: left;
        font-size: 16px;
        line-height: 130%;
      }
    }
  }
}

.why_choose_section{
  //background: url('/build/images/main/back1.jpg') top center no-repeat !important;
  background-size: cover;
  margin-top: 101px;
  z-index: 1;
  .bg-effects-color{
    padding-top: 80px;
    padding-bottom: 80px;
    // background-color: hsla(204deg, 90%, 56%, 0.8);
    background-image: linear-gradient(90deg, rgba(9,187,251,0.9) 0%, rgba(71,139,235,0.9) 50%);
    width: 100%;
    @include media-breakpoint-up(lg) {
      width: 90%;
    }
  }
  .left_cont{
      max-width: 420px;
      margin-left: 60px;
      margin-right: 61px;
      h2,.h2{
          color: #fff;
          padding-bottom: 0;
          border: none;
          text-align: left !important;
          max-width: none;
          letter-spacing: .5px;
          margin-bottom: 20px;
          -webkit-text-fill-color:initial;
      }
      ul{
          list-style: none;
          li{
              color: #fff;
              margin-bottom: 10px;
              position: relative;
              padding-left: 25px;
              font-size: 20px;
              font-family: 'Roboto', sans-serif;
              font-weight: 400;
              &::before{
                  content: "";
                  position: absolute;
                  left: 0;
                  top: 6px;
                  background: url('/build/images/icons/wrarrow.png') top center no-repeat;
                  background-size: cover;
                  width: 10px;
                  height: 15px;
              }
              &:last-child{
                  margin-bottom: 0;
              }
          }
      }
  }
  .right_img{
      max-width: 454px;
  }
  h3,.h3{
    color: #fff;
    font-size: 2.35rem;
    max-width: 596px;
    margin-bottom: 35px;
    text-align: center;
    line-height: 40px;
  }
  @media (max-width: 991px){
    margin-top: 50px;
    .left_cont ul li{
      font-size: 18px;
    }
    .right_img{
      max-width: 300px;
    }
    .left_cont{
      margin-left: 0;
      margin-right: 0;
      max-width: calc(100% - 300px);
      padding-right: 30px;
    }
    .bg-effects-color{
      padding-top: 60px;
      padding-bottom: 45px;
    }
  }
  @media (max-width: 768px){
    .why_choose_section_content{
      flex-direction: column;
      align-items: center;
    }
    .left_cont{
      max-width: 100%;
      padding-right: 0;
      margin-bottom: 30px;
    }
    .bg-effects-color{
      // width: calc(100% - 30px);
      width: 95%;
    }
    h3,.h3{
      font-size: 24px;
    }
  }
  @media (max-width: 400px){
    .right_img{
      max-width: 100%;
    }
  }
}

.engaging_section{
  background: none;
  margin-top: 0;
  .outter_wrap{
      width: 100%;
      background: url(/build/images/main/back1.jpg) top center no-repeat;
      background-size: cover;
  }
  h2,.h2{
      max-width: 615px;
      padding-bottom: 0;
      border: none;
      text-align: center;
      margin-bottom: 60px;
  }
  .plant{
      position: absolute;
      top: -185px;
      right: 0;
      z-index: 2;
  }
  .slider_wrap{
      max-width: 1030px;
      padding-right: 0;
      position: relative;
  }
  .inside{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      figure{
        position: relative;
        margin-bottom: 0;
        .icon{
          position: absolute;
          top: 1px;
          left: -22px;
          width: 54px;
          z-index: 2;
        }
      }
      .avatar{
          width: 148px;
          border-radius: 50%;
          object-fit: cover;
      }
      .text_container{
          width: 100%;
          max-width: 500px;
          margin-left: 39px;
          max-height: 250px;
          overflow: hidden;
          .text_area{
              width: 100%;
              font-size: 20px;
              line-height: 130%;
              margin-bottom: 0;
              span{
                font-family: Roboto;
                font-weight: 400;
              }
          }
      }
  }
  .dots_cont{
      position: absolute;
      left: 403px;
      bottom: 60px;
      .slick-dots{
          position: static;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          li{
              margin: 0;
              margin-right: 7px;
              &:last-child{
                  margin-right: 0;
              }
              button{
                  font-size: 0;
                  text-indent: -100000px;
                  width: 15px;
                  height: 15px;
                  border: 1px solid #fff;
                  border-radius: 50%;
                  padding: 0;
                  margin: 0;
              }
          }
          .slick-active{
              button{
                  background: #fff;
              }
          }
      }
  }
  .arrow{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 3;
      img{
          width: 12px;
      }
  }
  .arrow_left{
      left: -50px;
  }
  .arrow_right{
      right: 100px;
  }
  .slick-disabled{
      opacity: 0;
      visibility: hidden;
  }
  @media (max-width: 991px){
    .inside .text_container .text_area{
      line-height: 120%;
      font-size: 20px;
    }
    .inside .avatar{
      width: 200px;
      height: 200px;
    }
    .plant{
      height: 300px;
    }
    .slider_wrap{
      padding-right: 20px;
    }
    .arrow{
      display: none !important;
    }
    .dots_cont{
      bottom: 0;
    }
  }
  @media (max-width: 768px){
    .inside{
      flex-direction: column;
      align-items: center;
      .text_container{
        max-width: 100%;
        margin-left: 0;
        margin-top: 30px;
        .text_area{
          font-size: 16px;
          text-align: center;
        }
      }
    }
    .dots_cont{
      left: 50%;
      transform: translateX(-50%);
    }
    .dots_cont{
      bottom: -20px;
    }
    .plant{
      display: none;
    }
    .name{
      text-align: center;
      font-size: 18px;
      margin-bottom: 0;
    }
    .slider_wrap{
      padding-right: 0;
    }
  }
}

.principles_section{
  padding-top: 100px;
  padding-bottom: 100px;
  .plant{
    position: absolute;
    top: -185px;
    right: 0;
    z-index: 2;
  }
  h2,.h2{
    max-width: 725px;
    line-height: 45px;
    span{
      color: #666666;
      -webkit-text-fill-color:initial
    }
  }
  ul{
    padding-top: 56px;
    max-width: 876px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    padding-right: 0;
    li{
      display: flex;
      flex-direction: column;
      align-items: center;
      img{
        margin-bottom: 15px;
        width: 70px;
      }
      p{
        width: 100%;
        text-align: center;
        color: #000000;
        font-family: Roboto;
        font-size: 20px;
        font-weight: 300;
        line-height: 27.99px;
      }
    }
  }
  &.principles_section2{
    padding-bottom: 0 !important;
    .bg-effects-color{
      padding-top: 70px;
      padding-bottom: 62px;
      background-image: linear-gradient(90deg, rgba(9, 187, 251, 0.9) 0%, rgba(71, 139, 235, 0.9) 50%);
      width: calc(100% - 5rem);
    }
    .outter_wrap{
      width: 100%;
      background: url(/build/images/kini/back1.jpg) top center no-repeat;
      background-size: cover;
    }
    h2,.h2{
      color: #fff;
      -webkit-text-fill-color:initial;
      border-bottom: none;
    }
    ul li p{
      color: #fff;
    }
  }
  @media (max-width: 768px){
    padding: 40px 0;
    ul{
      padding-top: 15px;
      align-items: center !important;
      flex-wrap: wrap;
      justify-content: center !important;
      li{
        margin-bottom: 20px;
        width: 33.33%;
        &:last-child{
          margin-bottom: 0;
        }
        img{
          width: 60px;
        }
        p{
          font-size: 16px;
          line-height: 130%;
        }
      }
    }
    h2,.h2{
      font-size: 32px;
    }
    &.principles_section2{
      .plant{
        display: none;
      }
      .bg-effects-color{
        width: 95%;
      }
    }
  }
}

.gr-section{
  background-image: linear-gradient(90deg, rgb(9, 187, 251) 20%, rgb(71, 139, 235) 100%);
}

.text_image_section{
  padding: 70px 0;
  .container{
    max-width: 960px;
  }
  .plant{
    position: absolute;
    top: -185px;
    right: 0;
    z-index: 2;
  }
  .plant_left{
    position: absolute;
    top: -178px;
    left: 0;
    z-index: 2;
  }
  .text_container{
    max-width: 445px;
    padding: 0;
    h3,.h3{
      background: linear-gradient(90deg, #09bbfb, #478beb);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 30px;
      line-height: 34px;
      margin-bottom: 12px;
      text-transform: uppercase;
    }
    p{
      font-size: 18px;
      line-height: 25.99px;
    }
  }
  .image_cont{
    max-width: 435px;
    padding: 0;
    .main_img{
      width: 100%;
      object-fit: cover;
    }
  }
  &.text_image_section_white{
    .text_container{
      h3,.h3,p{
        color: #fff;
        background: none;
        -webkit-text-fill-color: initial;
      }
      h3, .h3{
        line-height: 33px;
        letter-spacing: 0.7px;
      }
    }
  }
  @media (max-width: 1300px){
    .plant{
      right: -80px;
      img{
        height: 650px;
      }
    }
    .plant_left{
      left: -80px;
      img{
        height: 650px;
      }
    }
  }
  @media (max-width: 991px){
    padding-top: 60px;
    padding-bottom: 40px;
    .plant_left,.plant{
      display: none;
    }
    .container .row{
      flex-direction: column;
    }
    .text_container{
      order: 1;
      margin-top: 0;
      max-width: 100%;
      margin-bottom: 30px;
      h3,.h3{
        font-size: 24px;
        font-weight: 500;
      }
    }
    .image_cont{
      order: 2;
      max-width: 100%;
      max-width: 650px;
    }
  }
  @media (max-width: 768px){
    .text_container p{
      font-size: 16px;
      line-height: 130%;
      text-align: left;
    }
    .text_container{
      h3,.h3{
        text-align: left;
        font-size: 30px;
      }
    }
  }
}

// .how_it_works{
//   background: url('/build/images/main/back1.jpg') top center no-repeat;
//   background-size: cover;
//   padding: 30px 0;
//   .gradient,.mobile_h2{
//     display: none;
//   }
//   .container{
//     max-width: 960px;
//     position: relative;
//     z-index: 4;
//   }
//   .overlay{
//     position: absolute;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     z-index: 1;
//     background: #ebecef;
//     opacity: .9;
//   }
//   .blue_back{
//     width: 48%;
//     position: absolute;
//     top: 0;
//     right: 0;
//     bottom: 0;
//     height: 100%;
//     z-index: 2;
//     background: #3691ee;
//   }
//   .left_img{
//     margin-left: 112px;
//     width: 210px;
//   }
//   .text_area{
//     width: 100%;
//     max-width: 384px;
//     h2,.h2,p{
//       color: #fff;
//     }
//     h2,.h2{
//       -webkit-text-fill-color:initial;
//       padding-bottom: 0 !important;
//       border-bottom: none;
//     }
//     p{
//       font-size: 18px;
//       line-height: 150%;
//       letter-spacing: normal;
//       span{
//         font-weight: 400;
//         font-family: Roboto;
//       }
//     }
//   }
//   .plant{
//     position: absolute;
//     top: -185px;
//     right: 0;
//     z-index: 3;
//   }
//   @media (max-width: 1300px){
//     .plant{
//       right: -80px;
//       img{
//         height: 650px;
//       }
//     }
//   }
//   @media (max-width: 991px){
//     .plant{
//       display: none;
//     }
//     .container{
//       flex-direction: column;
//       align-items: center;
//     }
//     .left_img{
//       margin-left: 0;
//     }
//     .overlay{
//       display: none;
//     }
//     .blue_back{
//       width: 100%;
//       opacity: .8;
//     }
//     .text_area{
//       max-width: 100%;
//       margin-top: 10px;
//     }
//   }
//   @media (max-width: 768px){
//     position: relative;
//     padding-bottom: 70px;
//     .text_area p{
//       font-size: 16px;
//       line-height: 130%;
//       text-align: left;
//     }
//     .text_area{
//       margin-top: 40px;
//       h2,.h2{
//         text-align: center;
//       }
//     }
//     .blue_back{
//       background: #eff1f5;
//       opacity: 0.9;
//     }
//     .gradient{
//       display: block;
//       width: 100%;
//       position: absolute;
//       left: 0;
//       right: 0;
//       bottom: 0;
//       z-index: 3;
//       height: 640px;
//     }
//     .mobile_h2{
//       display: block;
//       text-align: center;
//       padding-bottom: 0;
//       border-bottom: none;
//       margin-bottom: 30px;
//       font-size: 48px;
//     }
//     .text_area h2,.text_area .h2{
//       display: none;
//     }
//     .plant{
//       display: block;
//       width: 75px;
//       right: -15px;
//       top: initial;
//       bottom: -110px;
//       z-index: 4;
//     }
//   }
// }

.how_it_works_section{
  padding-top: 90px;
  h2{
    margin-bottom: 20px;
    font-size: 70px;
  }
  .top_text{
    width: 100%;
    max-width: 658px;
    margin-left: auto;
    margin-right: auto;
    color: #000000;
    font-family: Roboto;
    font-size: 20px;
    font-weight: 300;
    line-height: 130%;
    margin-bottom: 65px;
    span{
      font-family: Roboto;
      color: #4285ea;
      font-weight: 500;
    }
  }
  .bottom_area{
    width: 100%;
    background: #edf0f4;
    padding-top: 125px;
    padding-bottom: 240px;
    .content{
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      position: relative;
      max-width: 960px;
    }
    .filter_item{
      position: absolute;
      z-index: 2;
      width: 100%;
      .text{
        width: calc(100% - 108px);
        opacity: 1;
        visibility: visible;
        .title{
          width: 100%;
          background: linear-gradient(
          90deg, #09bbfb, #478beb);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: 30px;
          font-weight: bold;
          text-transform: uppercase;
          padding-bottom: 8px;
          border-bottom: 2px solid #75a5ed;
          margin-bottom: 8px;
        }
        .text_p{
          width: 100%;
          color: #000000;
          font-family: Roboto;
          font-size: 15px;
          font-weight: 300;
          line-height: 130%;
          max-width: 284px;
          strong{
            font-family: Roboto;
            font-weight: 600;
          }
        }
      }
      figure{
        width: 108px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
        animation: pulse 2s infinite;
        border-radius: 50%;
        img{
          width: 100%;
        }
      }
      @keyframes pulse {
        0% {
          box-shadow: 0 0 0 0 rgba(9, 187, 251,.7);
        }
      
        70% {
          box-shadow: 0 0 0 15px rgba(71, 139, 235,0);
        }
      
        100% {
          box-shadow: 0 0 0 0 rgba(71, 139, 235,0);
        }
      }
      &.touch_dev{
        .text{
          opacity: 0;
          visibility: hidden;
        }
      }
    }
    .filter_item1{
      max-width: 445px;
      top: -45px;
      left: 0;
    }
    .filter_item2{
      max-width: 428px;
      top: 45px;
      right: 0;
      .text{
        .title{
          padding-left: 44px;
        }
        .text_p{
          padding-left: 44px;
          max-width: 320px;
        }
      }
    }
    .filter_item3{
      max-width: 445px;
      top: 207px;
      left: 0;
      width: 100%;
      figure{
        cursor: pointer;
      }
      ul{
        width: 100%;
        list-style-type: disc;
        max-width: 300px;
        padding-left: 1rem;
        li{
          color: #000000;
          font-family: Roboto;
          font-size: 15px;
          font-weight: 300;
          line-height: 130%;
          margin-bottom: 10px;
          &:last-child{
            margin-bottom: 0;
          }
          strong{
            font-family: Roboto;
            font-weight: 600;
          }
        }
      }
      figure{
        margin-top: 40px;
      }
    }
    .filter_item4{
      max-width: 416px;
      top: 322px;
      right: 0;
      .text{
        .title{
          padding-left: 44px;
        }
        .text_p{
          padding-left: 44px;
          max-width: 320px;
        }
      }
    }
  }
  .plant{
    position: absolute;
    top: -260px;
    right: 0;
    z-index: 2;
  }
  .filter_tab{
    display: none;
    position: relative;
    z-index: 3;
    padding-top: 60px;
    .filter_item{
      display: none;
      position: static;
      .text{
        opacity: 1;
        visibility: visible;
        .text_p{
          max-width: 100%;
        }
      }
      ul{
        li{
          color: #000000;
          font-family: Roboto;
          font-size: 15px;
          font-weight: 300;
          line-height: 130%;
          margin-bottom: 10px;
          &:last-child{
            margin-bottom: 0;
          }
          strong{
            font-family: Roboto;
            font-weight: 600;
          }
        }
      }
      &.active{
        display: flex;
        flex-direction: column;
        align-items: center !important;
      }
      &.touch_dev{
        .text{
          opacity: 1;
          visibility: visible;
          ul{
            padding-left: 1rem;
          }
        }
      }
    }
    .filter_item3{
      list-style-type: disc;
      max-width: 300px;
      padding-left: 14px;
    }
    &.active{
      display: block;
      width: 100%;
    }
  }
  &.how_it_works_section2{
    .bottom_area .filter_item1{
      left: 0;
      top: -60px;
      max-width: 655px;
    }
    .bottom_area{
      .content{
        align-items: flex-end;
      }
      .filter_img{
        max-width: 650px;
      }
      .filter_item .text .text_p{
        max-width: 246px;
      }
    }
    .bottom_area .filter_item3{
      max-width: 613px;
    }
  }
  // @media (hover: hover) {
  //   .bottom_area{
  //     .filter_item{
  //       &:hover{
  //         .text{
  //           opacity: 1;
  //           visibility: visible;
  //         }
  //       }
  //     }
  //   }
  // }
  @media (max-width: 991px){
    padding-top: 40px;
    h2,.h2{
      font-size: 48px;
    }
    .plant{
      width: 75px;
      right: 0px;
      top: -30px;
    }
    .top_text{
      font-size: 16px;
    }
    .bottom_area{
      padding-top: 100px;
      padding-bottom: 130px;
    }
    .bottom_area .content{
      max-width: 289px;
      margin-left: auto;
      margin-right: auto;
    }
    .filter_tab .filter_item .text{
      width: 100%;
    }
    &.how_it_works_section2 {
      .bottom_area .content{
        max-width: 100%;
        align-items: center;
      }
      .bottom_area .filter_item1{
        max-width: 305px;
      }
      .bottom_area .filter_item3{
        max-width: 288px;
      }
      .bottom_area .filter_img{
        max-width: 500px;
      }
      .bottom_area .filter_item .text .text_p{
        max-width: 100%;
      }
    }
  }
  @media (max-width: 767px){
    .bottom_area .filter_item .text{
      opacity: 0;
      visibility: hidden;
    }
    .filter_tab .filter_item .text{
      opacity: 1;
      visibility: visible;
    }
  }
  @media (max-width: 500px){
    &.how_it_works_section2{
      .bottom_area .filter_item3{
        max-width: 40%;
      }
      .bottom_area .filter_item1{
        max-width: 50%;
      }
      .bottom_area .filter_img{
        max-width: 100%;
      }
    }
  }
}

.components_section{
  padding-top: 80px;
  padding-bottom: 16px;
  .container{
    max-width: 1000px;
  }
  .row_item{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 50px;
    margin-bottom: 50px;
    border-bottom: 1px solid #d0d0d0;
    &:last-child{
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: none;
    }
    figure{
      width: 110px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      img{
        max-width: 100%;
      }
    }
    .right_text{
      width: calc(100% - 110px);
      padding-left: 40px;
      .title{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background: linear-gradient(90deg, #09bbfb, #478beb);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: Anton;
        font-size: 28px;
        margin-bottom: 15px;
        text-transform: uppercase;
        img{
          width: 41px;
          margin-right: 17px;
        }
      }
      .text_cont{
        width: 100%;
        padding-left: 60px;
        strong{
          color: #000000;
          font-family: Roboto;
          font-size: 18px;
          line-height: 25.99px;
          font-weight: 600;
        }
        p{
          width: 100%;
          color: #000000;
          font-family: Roboto;
          font-size: 18px;
          font-weight: 300;
          line-height: 25.99px;
          margin-bottom: 0;
        }
        ul{
          padding: 0;
          margin: 0;
          list-style: none;
          width: 100%;
          margin-top: 10px;
          li{
            width: 100%;
            color: #000000;
            font-family: Roboto;
            font-size: 18px;
            font-weight: 300;
            line-height: 25.99px;
            margin-bottom: 4px;
            &:last-child{
              margin-bottom: 0;
            }
            span{
              color: #4285ea;
              font-family: Roboto;
            }
          }
        }
      }
    }
  }
  @media (max-width: 991px){
    .row_item .right_text{
      padding-left: 25px;
    }
    .row_item .right_text .title{
      font-size: 24px;
    }
  }
  @media (max-width: 768px){
    padding: 40px 0;
    .row_item{
      flex-direction: column;
      align-items: center;
      padding-bottom: 30px;
      margin-bottom: 30px;
      &:last-child{
        margin-bottom: 0;
        padding-bottom: 0;
      }
      .right_text{
        width: 100%;
        padding-left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        .text_cont{
          padding-left: 0;
          font-size: 16px;
          line-height: 130%;
          text-align: left;
          max-width: 300px;
        }
      }
    }
    .row_item .right_text .title{
      justify-content: flex-start;
      max-width: 300px;
    }
  }
}

.ingridients_section{
  padding: 90px 0;
  .bottom_img{
    position: absolute;
    width: 234px;
    right: 0;
    bottom: -76px;
    z-index: 1;
    opacity: .2;
  }
  h2,.h2{
    -webkit-text-fill-color:initial;
    color: #fff;
    text-align: center;
    max-width: 100%;
    padding-bottom: 0;
    border-bottom: none;
    margin-bottom: 50px;
  }
  .container{
    max-width: 970px;
  }
  .column{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 280px;
    img{
      width: 153px;
      object-fit: cover;
      margin-bottom: 23px;
    }
    .title{
      width: 100%;
      text-align: center;
      font-family: Anton;
      color: #fff;
      font-size: 26px;
      line-height: 31.99px;
      text-transform: uppercase;
      margin-bottom: 15px;
    }
    .text{
      width: 100%;
      text-align: center;
      color: #fff;
      font-family: Roboto;
      font-size: 16px;
      font-weight: 300;
      line-height: 23.99px;
      margin-bottom: 0;
      span{
        font-weight: 500;
        font-family: Roboto;
        color: #fff;
      }
    }
  }
  @media (max-width: 991px){
    .container{
      flex-direction: column;
      align-items: center !important;
    }
    .column{
      margin-bottom: 30px;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
  @media (max-width: 768px){
    padding-top: 60px;
    padding-bottom: 230px;
  }
}

.filters_section{
  padding-top: 90px;
  padding-bottom: 150px;
  z-index: 2;
  .plant_left{
    position: absolute;
    top: -178px;
    left: 0;
    z-index: 2;
  }
  .container{
    max-width: 1136px;
  }
  h2,.h2{
    max-width: 460px;
    text-align: center;
    padding-bottom: 0;
    border-bottom: none;
    margin-bottom: 50px;
    font-weight: bold;
    line-height: 48px;
  }
  .slider_wrap{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    .arrow{
      img{
        width: 29px;
      }
    }
    .slick-list{
      padding-top: 18px;
    }
    .slick-track
    {
        display: flex !important;
    }

    .slick-slide
    {
        height: inherit !important;
    }
    .slider{
      width: calc(100% - 74px * 2 - 20px);
      .slick-slide{
        margin: 0 10px;
        div{
          height: 100%;
        }
      }
      .inside{
        width: 100%;
        border: 1px solid #3dc4fa;
        border-radius: 4px;
        position: relative;
        padding: 35px 30px;
        .top_img{
          position: absolute;
          width: 36px;
          top: -18px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 2;
        }
        figure{
          height: 145px;
          width: 100%;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 24px;
          img{
            max-width: 100%;
            max-height: 100%;
          }
        }
        p{
          text-align: center;
          font-size: 18px;
          line-height: 130%;
          color: #000;
          margin-bottom: 0;
        }
      }
    }
  }
  .bottom_text{
    width: 100%;
    max-width: 530px;
    text-align: center;
    color: #4285ea;
    font-family: Roboto;
    font-size: 18px;
    line-height: 25.99px;
    a{
      font-family: Roboto;
      font-weight: 400;
      text-decoration: underline !important;
    }
  }
  &.filters_section2{
    padding-bottom: 110px;
    .slider_wrap .slider .inside{
      background-color: #fff;
    }
    .slider_wrap{
      justify-content: center;
    }
    .bottom_text{
      color: #000000;
      margin-bottom: 0;
      a{
        color: #000000;
      }
    }
    .arrow{
      display: none !important;
    }
  }
  @media (max-width: 1300px){
    .plant_left{
      left: -80px;
      img{
        height: 650px;
      }
    }
  }
  @media (max-width: 991px){
    padding: 60px 0;
    .slider_wrap .slider .inside figure{
      margin-bottom: 20px;
    }
  }
  @media (max-width: 768px){
    padding: 40px 0;
    .plant_left{
      display: none;
    }
    h2,.h2{
      margin-bottom: 40px;
      font-size: 36px;
    }
    .slider_wrap .arrow img{
      width: 20px;
    }
    .slider_wrap .slider{
      width: calc(100% - 70px);
    }
    .slider_wrap .slider .inside p{
      font-size: 16px;
      line-height: 130%;
    }
    .slider_wrap .slider .inside{
      padding-left: 15px;
      padding-right: 15px;
    }
    &.filters_section2 .arrow{
      display: inline !important;
    }
  }
}

.contaminants_removal_section{
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative;
  z-index: 3;
  background-color: #edf0f4;
  .container{
    max-width: 800px;
  }
  .plant{
    position: absolute;
    top: -185px;
    right: 0;
    z-index: 2;
  }
  h2,.h2{
    margin-bottom: 50px;
    text-align: center;
  }
  .row_item{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 12px;
    .column{
      width: 100%;
      max-width: 140px;
      img{
        width: 100%;
        margin-bottom: 15px;
      }
      p{
        font-size: 18px;
        text-align: center;
        margin-bottom: 0;
      }
    }
  }
  .btn-blue2{
    padding-left: 29px;
    padding-right: 29px;
    padding-top: 10px;
    padding-bottom: 10px;
    figure{
      margin-left: 0;
    }
    span{
      width: calc(100% - 26px);
      padding-right: 12px;
      font-family: "Open Sans", sans-serif;
      font-weight: 400;
    }
  }
  &.contaminants_removal_section2{
    z-index: 2;
    background-color: #fff;
  }
  @media (max-width: 1300px){
    .plant{
      right: -80px;
      img{
        height: 650px;
      }
    }
  }
  @media (max-width: 991px){
    .plant{
      display: none;
    }
    .row_item{
      flex-direction: column;
      align-items: center;
      margin-bottom: 30px;
      .column{
        margin-bottom: 30px;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  }
  @media (max-width: 768px){
    padding-top: 60px;
    padding-bottom: 40px;
    h2,.h2{
      margin-bottom: 30px;
      font-size: 42px;
    }
    .row_item .column img{
      width: 120px;
    }
    .row_item .column p{
      font-size: 16px;
      line-height: 130%;
    }
    .row_item .column{
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 284px;
    }
  }
}

.customers_say_section{
  padding-top: 142px;
  h2,.h2{
    font-size: 48px;
    margin-bottom: 45px;
  }
  .inside .text_container{
    max-height: none !important;
    h3,.h3{
      text-transform: uppercase;
      text-align: left;
      margin-bottom: 20px;
      max-width: 450px;
    }
    .text_area{
      font-size: 18px;
      line-height: 27.99px;
    }
    ul{
      padding: 0;
      margin: 0;
      margin-left: 20px;
      li{
        color: #ffffff;
        font-family: Roboto;
        font-size: 18px;
        line-height: 27.99px;
        text-align: left;
      }
    }
  }
  .inside .avatar{
    width: 355px;
  }
  .inside .text_container{
    max-width: calc(100% - 355px);
    margin-left: 0;
    padding-left: 40px;
  }
  .slide{
    width: 100%;
  }
  .inside{
    padding-right: 140px;
    padding-left: 0;
    justify-content: space-between;
  }
  @media (max-width: 991px){
    padding-top: 60px;
    h2,.h2{
      font-size: 2.2rem;
    }
    .inside{
      padding-right: 0;
      flex-direction: column;
      align-items: center;
    }
    .inside .avatar{
      width: auto;
      margin-bottom: 30px;
    }
    .inside .text_container{
      max-width: 100%;
      padding-left: 0;
      h3,.h3{
        font-size: 1.8rem;
      }
    }
    .inside .text_container .text_area{
      text-align: left;
    }
  }
  @media (max-width: 768px){
    padding: 40px 0;
    h2,.h2{
      margin-bottom: 40px;
    }
    h3,.h3{
      font-size: 46px;
    }
    .inside .text_container{
      margin-top: 0;
      h3,.h3,p{
        text-align: center;
        max-width: 100%;
      }
      ul{
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
      }
    }
    .inside .text_container .text_area{
      text-align: left;
    }
  }
  @media (max-width: 500px){
    .inside .text_container ul li{
      text-align: left;
      margin-left: 16px;
    }
  }
}

.water_best_section{
  padding-top: 100px;
  padding-bottom: 40px;
  .faq{
    display: none;
  }
  .container{
    max-width: 1135px;
  }
  .left_cont{
    width: 100%;
    max-width: 310px;
    h2,.h2{
      font-size: 48px;
      text-align: left;
      margin-bottom: 15px;
      padding-bottom: 0;
      border-bottom: none;
      font-weight: bold;
    }
    img{
      width: 100%;
    }
  }
  .right_cont{
    width: calc(100% - 310px);
    padding-left: 45px;
    .tab_item{
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-bottom: 2px solid #cfcfcf;
      a{
        display: inline-flex;
        padding-bottom: 10px;
        color: #4285ea;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 500;
        text-transform: uppercase;
        position: relative;
        margin-right: 32px;
        &:last-child{
          margin-right: 0;
        }
        &:hover,&.active{
          &::before{
            display: block;
          }
        }
        &::before{
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          height: 2px;
          width: 100%;
          background: #4285ea;
          display: none;
        }
      }
    }
    .tabs_wrap{
      width: 100%;
      padding-top: 25px;
      .tab{
        width: 100%;
        display: none;
        p{
          width: 100%;
          color: #000000;
          font-family: Roboto;
          font-size: 16px;
          font-weight: 300;
          line-height: 25.99px;
          margin-bottom: 10px;
          &:last-child{
            margin-bottom: 0;
          }
          span{
            font-family: Roboto;
            font-weight: 500;
            font-size: 16px;
          }
        }
        .btn_link{
          color: #000000;
          font-family: Roboto;
          font-size: 18px;
          font-weight: 400;
          text-decoration: underline;
        }
        .title{
          color: #4285ea;
          font-family: Anton;
          font-size: 30px;
          font-weight: bold;
          text-transform: uppercase;
          background: linear-gradient(90deg, #09bbfb, #478beb);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          margin-bottom: 15px;
        }
        .table_wrap{
          width: 100%;
          .row_item{
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: stretch;
            &:nth-child(odd){
              background: #edf0f4;
            }
            .left_p{
              margin: 0;
              font-weight: 400;
              padding: 28px 19px;
              width: 100%;
              max-width: 280px;
              display: inline-flex;
              justify-content: flex-start;
              align-items: center;
            }
            .right_p{
              margin: 0;
              font-weight: 400;
              padding: 15px 0;
              width: 100%;
              max-width: 475px;
              display: inline-flex;
              justify-content: flex-start;
              align-items: center;
            }
          }
        }
        &.active{
          display: block;
        }
      }
    }
    .replacements_wrap{
      width: 100%;
      background: url('/build/images/main/back_gradient2.png') top center no-repeat;
      background-size: cover;
      padding: 14px 34px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 22px;
      .image{
        width: 80px;
      }
      p{
        text-align: center;
        font-weight: 400 !important;
        color: #fff !important;
        margin: 0 !important;
        width: auto !important;
      }
      .btn-blue2{
        font-weight: 400;
      }
    }
  }
  .bottom_cont{
    width: 100%;
    margin-top: 58px;
    background: #edf0f4;
    padding: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    img{
      width: 128px;
    }
    p{
      width: calc(100% - 128px);
      padding-left: 40px;
      margin-bottom: 0;
      font-size: 18px;
      line-height: 25px;
      color: #000000;
    }
    @media (max-width: 991px){
      padding: 40px;
    }
    @media (max-width: 768px){
      margin-top: 30px;
      padding: 30px 15px;
      flex-direction: column;
      align-items: center;
      p{
        width: 100%;
        padding-left: 0;
        margin-top: 15px;
        text-align: center;
        font-size: 16px;
        line-height: 130%;
      }
    }
  }
  @media (max-width: 991px){
    .container{
      display: flex !important;
      flex-direction: column;
      align-items: center !important;
      h2,.h2{
        font-size: 2.2rem;
      }
    }
    .right_cont{
      padding-left: 0;
      width: 100%;
      margin-top: 15px;
    }
  }
  @media (max-width: 991px){
    padding-top: 40px;
    .left_cont{
      max-width: 500px;
      display: flex;
      flex-direction: column;
      align-items: center;
      h2,.h2{
        text-align: center;
      }
      img{
        max-width: 160px;
      }
    }
    .right_cont .tab_item{
      flex-direction: column;
      align-items: center;
      border-bottom: none;
      padding-bottom: 0;
      a{
        margin-right: 0;
        margin-bottom: 10px;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
    .right_cont .tabs_wrap .tab p{
      font-size: 16px;
      line-height: 130%;
    }
  }
  @media (max-width: 768px){
    .left_cont{
      flex-direction: row;
      justify-content: center;
      h2,.h2{
        order: 2;
        font-size: 70px;
        max-width: 181px;
        margin-left: 20px;
        text-align: left;
      }
      img{
        margin-left: -30px;
        width: 190px;
        max-width: 190px;
      }
    }
    .right_cont{
      .tab_item,.tabs_wrap{
        display: none;
      }
      .faq{
        width: 100%;
        display: block;
        border-top: 1px solid #c7c7c7;
        padding-top: 25px;
        .faq_item{
          border-bottom: 2px solid #c7c7c7;
          margin-bottom: 25px;
          .faq_btn{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #4285ea;
            font-family: Anton;
            font-size: 22px;
            line-height: 27.98px;
            text-transform: uppercase;
            padding-bottom: 20px;
          }
          .btns_right{
            .open{
              display: none;
            }
          }
          .faq_text{
            width: 100%;
            padding-bottom: 15px;
            padding-top: 15px;
            display: none;
            p{
              width: 100%;
              color: #000000;
              font-family: Roboto;
              font-size: 16px;
              font-weight: 300;
              line-height: 23.99px;
              span{
                font-family: Roboto;
                font-weight: 500;
              }
            }
            .btn_link{
              font-weight: 400;
              color: #000;
              font-family: Roboto;
            }
            .title{
              color: #4285ea;
              font-family: Anton;
              font-size: 20px;
              font-weight: bold;
              text-transform: uppercase;
              background: linear-gradient(90deg, #09bbfb, #478beb);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              margin-bottom: 15px;
              line-height: 130% !important;
            }
            .table_wrap{
              width: 100%;
              .row_item{
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: stretch;
                &:nth-child(odd){
                  background: #edf0f4;
                }
                .left_p{
                  margin: 0;
                  font-weight: 400;
                  padding: 19px;
                  width: 100%;
                  max-width: 220px;
                  display: inline-flex;
                  justify-content: flex-start;
                  align-items: center;
                  font-size: 16px;
                }
                .right_p{
                  margin: 0;
                  font-weight: 400;
                  padding: 15px 0;
                  width: 100%;
                  max-width: 475px;
                  display: inline-flex;
                  justify-content: flex-start;
                  align-items: center;
                  font-size: 16px;
                }
              }
            }
            &.active{
              display: block;
            }
          }
          .replacements_wrap{
            width: 100%;
            background: url('/build/images/main/back_gradient2.png') top center no-repeat;
            background-size: cover;
            padding: 14px 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 22px;
            .image{
              width: 80px;
            }
            p{
              text-align: center;
              font-weight: 400 !important;
              color: #fff !important;
              margin: 10px 0 20px 0 !important;
              width: auto !important;
            }
            .btn-blue2{
              font-weight: 400;
            }
          }
          &.active{
            .btns_right{
              .close{
                display: none;
              }
              .open{
                display: block;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 400px){
    .left_cont h2,.left_cont ,h2{
      font-size: 50px !important;
    }
  }
}

.questions_section{
  padding: 63px 0;
  .plant_left{
    position: absolute;
    top: -178px;
    left: 0;
    z-index: 2;
  }
  .container{
    max-width: 1135px;
  }
  h2,.h2{
    width: auto;
    font-size: 48px;
    color: #fff;
    padding: 0;
    margin: 0;
    -webkit-text-fill-color:initial;
    border-bottom: none;
  }
  .phone_cont{
    width: 100%;
    max-width: 400px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 60px;
    img{
      width: 63px;
    }
    ul{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0;
      margin: 0;
      list-style: none;
      li{
        a{
          color: #ffffff;
          font-family: Anton;
          font-size: 31px;
          line-height: 49.74px;
          text-transform: uppercase;
          letter-spacing: normal;
          letter-spacing: .6px;
        }
      }
    }
  }
  @media (max-width: 1300px){
    .plant_left{
      display: none;
    }
  }
  @media (max-width: 991px){
    padding: 40px 0;
    .container{
      flex-direction: column;
      align-items: center;
    }
    h2,.h2{
      font-size: 2rem;
    }
    .phone_cont{
      margin-right: 0;
      margin-top: 20px;
      max-width: 300px;
      img{
        width: 70px;
      }
    }
    .phone_cont ul li a{
      font-size: 24px;
      font-weight: 500;
      line-height: 30px;
    }
  }
  @media (max-width: 768px){
    padding: 30px 15px;
    position: relative;
    padding-top: 75px;
    h2,.h2{
      font-size: 44px;
      text-align: center;
    }
    .phone_cont ul li a{
      font-size: 22px;
    }
    .phone_cont img{
      width: 76px;
      position: absolute;
      top: -38px;
      left: 50%;
      transform: translateX(-50%);
    }
    .phone_cont{
      justify-content: center;
    }
    .plant_left{
      display: block;
      width: 75px;
      left: 0;
      top: -40px;
    }
  }
}

.questions_block_section{
  padding-top: 38px;
  padding-bottom: 102px;
  .container{
    max-width: 1135px;
  }
  .block{
    width: 50%;
    padding: 80px 71px 63px;
    h3,.h3{
      background: linear-gradient(90deg, #09bbfb, #478beb);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: Anton;
      font-size: 25px;
      line-height: 28px;
      text-transform: uppercase;
    }
    p{
      font-size: 18px;
      margin-bottom: 25px;
    }
    .btn-blue2{
      padding-left: 24px;
      padding-right: 23px;
    }
  }
  @media (max-width: 991px){
    padding-bottom: 60px;
    .container{
      flex-direction: column;
      align-items: center !important;
    }
    .block{
      width: 100%;
      max-width: 400px;
      padding: 40px 25px;
      h3,.h3{
        font-size: 24px;
      }
    }
  }
  @media (max-width: 768px){
    padding-top: 0;
    .block p{
      font-size: 16px;
      line-height: 130%;
    }
    .container{
      padding: 0 !important;
    }
    .block{
      max-width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    h3,.h3{
      text-align: left;
    }
  }
}

.founder_section{
  h3,.h3{
    font-size: 40px;
    margin-bottom: 30px;
    max-width: 620px;
    text-transform: uppercase;
    &.de{
      line-height: 44px;
    }
  }
  .video_wrap{
    max-width: 540px;
    position: relative;
    width: 100%;
    .play_btn{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      z-index: 2;
      display: inline-flex;
      justify-content: flex-start;
      align-items: flex-start;
      width: 98px;
      padding: 8px;
      img{
        max-width: 100%;
      }
    }
    .thumbnail{
      width: 100%;
    }
    .vjs-big-play-centered .vjs-big-play-button {
      background-color: #4285ea;
      width: 80px;
      height: 80px;
      border: 0px;
      border-radius: 50%;
      font-size: 5em;
      margin-top: -0.81666em;
      margin-left: -0.81666em;
      .vjs-icon-placeholder:before{
        top: 2px;
      }
    }
  }
  @media (max-width: 991px){
    h3,.h3{
      font-size: 30px;
    }
  }
}

.recommended_section{
  padding-top: 90px;
  padding-bottom: 78px;
  .plant{
    position: absolute;
    top: 115px;
    right: 0;
    z-index: 2;
  }
  .container{
    max-width: 885px;
  }
  .recommended_wrap{
    margin-bottom: 10px;
    img{
      margin-right: 7px;
      width: 39px;
    }
    p{
      color: #666666;
      font-size: 26px;
      font-weight: bold;
      text-transform: uppercase;
      margin: 0;
    }
  }
  h2,.h2{
    padding-bottom: 0;
    border-bottom: none;
    color: #4285ea;
    font-size: 45px;
    font-weight: bold;
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
  }
  .raiting{
    max-width: 154px;
    margin-bottom: 29px;
  }
  .arrow{
    img{
      width: 29px;
    }
  }
  .slider_wrap{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .slider{
    width: calc(100% - 130px);
    .inside{
      width: 100%;
      padding: 5px;
      background-color: #f5f5f5;
      border-radius: 6px;
      img{
        width: 100%;
        border-radius: 8px;
      }
    }
  }
  .slick-dotted.slick-slider{
    margin-bottom: 0 !important;
  }
  .dots_cont_wrapper{
    width: 100%;
    .slick-dots{
      position: static;
    }
    .slick-dots li button{
      width: 15px;
      height: 15px;
      border-radius: 50%;
      border-radius: 50%;
      border: 1px solid #4285ea;
      font-size: 0;
      text-indent: -100000;
      margin: 0;
      &::before{
        display: none;
      }
    }
    .slick-dots li{
      justify-content: center;
      align-items: center;
      &.slick-active{
        button{
          background: #4285ea;
        }
      }
    }
  }
  @media (max-width: 1300px){
    .plant{
      right: -80px;
      img{
        height: 650px;
      }
    }
  }
  @media (max-width: 991px){
    padding: 60px 0;
    .plant{
      display: none;
    }
    h2,.h2{
      font-size: 30px;
    }
  }
  @media (max-width: 768px){
    padding: 40px 0;
    .arrow img{
      width: 20px;
    }
    .slider{
      width: calc(100% - 60px);
    }
  }
}

.companies_section{
  background: url(/build/images/main/back1.jpg) top center no-repeat;
  background-size: cover;
  padding: 44px 0;
  .overlay{
    position: absolute;
    top: 0;
    bottom: 0;
    background: #e8e6e9;
    opacity: 0.8;
    // width: calc(100% - 5rem);
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    @include media-breakpoint-up(lg) {
      width: 95%;
    }
  }
  .container{
    position: relative;
    z-index: 2;
    max-width: 930px;
  }
  .column{
    width: 100%;
    max-width: 329px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 14px;
    &:nth-child(2){
      border-left: 1px solid #b7bfc9;
      border-right: 1px solid #b7bfc9;
    }
    figure{
      height: 158px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 6px;
      img{
        max-width: 100%;
      }
    }
    p{
      width: 100%;
      color: #000000;
      font-family: Roboto;
      font-size: 18px;
      font-weight: 300;
      line-height: 25.99px;
      text-align: center;
    }
  }
  @media (max-width: 991px){
    .container{
      flex-direction: column;
      align-items: center !important;
    }
    .column:nth-child(2){
      border: none;
    }
    .column{
      margin-bottom: 20px;
      padding-bottom: 0;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
  @media (max-width: 768px){
    .column{
      padding-top: 0;
      border-bottom: 2px solid #b8bcc1;
      padding-bottom: 30px;
      &:nth-child(2){
        border-bottom: 2px solid #b8bcc1;
      }
      &:last-child{
        border-bottom: none;
      }
    }
  }
  @media (max-width: 400px){
    .overlay{
      // width: calc(100% - 60px);
      width: 100%;
    }
  }
}

.filter_pitcher{
  padding-top: 70px;
  .text_container{
    max-width: 620px;
    padding: 0;
    .top{
      margin-bottom: 34px;
      img{
        max-width: 190px;
      }
    }
    p{
      max-width: 536px;
    }
  }
  .right_image{
    max-width: 370px;
    padding: 0;
  }
  @media (max-width: 1199px){
    padding-top: 50px;
    .text_container{
      order: 2;
      margin-top: 20px;
    }
    .row{
      flex-direction: column;
    }
    .right_image{
      max-width: 300px;
    }
  }
  @media (max-width: 768px){
    padding-top: 35px;
    .right_image{
      max-width: 220px;
    }
    .text_container .top{
      justify-content: center !important;
      margin-bottom: 25px;
    }
    h2,.h2{
      text-align: center;
    }
    p{
      text-align: center;
    }
  }
}

.maunawai_table_section{
  padding-top: 80px;
  padding-bottom: 124px;
  .left_cont{
    width: 100%;
    max-width: 386px;
    padding: 0;
    margin-top: 134px;
    .row_item{
      width: 100%;
      color: #ffffff;
      font-family: Roboto;
      font-size: 20px;
      font-weight: 400;
      line-height: 130%;
      padding-left: 21px;
      padding-top: 17px;
      padding-bottom: 17px;
      padding-right: 10px;
      border-bottom: 2px solid #fff;
      background-image: linear-gradient(90deg, rgb(9, 187, 251) 20%, rgb(71, 139, 235) 100%);
      &:last-child{
        border-bottom: none;
      }
    }
  }
  .right_cont{
    width: 100%;
    max-width: 570px;
    padding: 0;
    .column_wrap{
      width: 33.33%;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-right: 2px solid #fff;
      position: relative;
      z-index: 2;
      &:last-child{
        border-right: none;
      }
      &:first-child{
        .td_item{
          background-color: #d0e0fa;
        }
      }
      figure{
        height: 55px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 13px;
        img{
          max-height: 100%;
        }
      }
      .title{
        width: 100%;
        color: #ffffff;
        font-family: Anton;
        font-size: 24px;
        line-height: 25.99px;
        text-align: center;
        text-transform: uppercase;
        padding: 19px 10px;
        background-color: hsl(204deg, 90%, 56%);
        border-bottom: 2px solid #fff;
        margin-bottom: 0;
      }
      .td_item{
        width: 100%;
        color: #000000;
        font-family: Roboto;
        font-size: 20px;
        font-weight: 400;
        line-height: 130%;
        text-align: center;
        padding: 17px 10px;
        border-bottom: 2px solid #fff;
        background-color: #edf0f4;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        &:nth-of-type(4){
          height: 88px;
        }
        &:last-child{
          border-bottom: none;
        }
      }
    }
    .plant{
      position: absolute;
      top: 102px;
      z-index: 1;
      left: 100%;
      max-width: 114px;
    }
  }
  @media (max-width: 991px){
    .row{
      flex-wrap: nowrap !important;
    }
    .left_cont{
      max-width: 40%;
    }
    .right_cont{
      max-width: 60%;
    }
    .right_cont .column_wrap .title{
      font-size: 18px;
    }
    .left_cont .row_item{
      font-size: 14px;
    }
    .right_cont .column_wrap .td_item{
      font-size: 16px;
      padding: 16px 10px;
    }
  }
  @media (max-width: 767px){
    .right_cont .plant{
      display: none;
    }
    .container{
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .row{
      margin-left: 0;
      margin-right: 0;
    }
    .right_cont .column_wrap .title{
      padding: 15px;
      word-break: break-all;
    }
    .left_cont{
      margin-top: 152px;
    }
    .left_cont .row_item{
      height: 99px;
      font-size: 16px;
      display: inline-flex;
      justify-content: flex-start;
      align-items: center;
    }
    .right_cont .column_wrap .td_item,.right_cont .column_wrap .td_item:nth-of-type(4){
      height: 99px;
      font-size: 16px;
    }
  }
  @media (max-width: 500px){
    .left_cont{
      max-width: 35%;
    }
    .right_cont{
      max-width: 65%;
    }
    .right_cont .column_wrap .title{
      height: 84px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
    }
    .left_cont .row_item{
      padding: 10px;
    }
  }
  @media (max-width: 400px){
    .right_cont .column_wrap .title{
      font-size: 18px;
    }
    .left_cont .row_item{
      font-size: 15px;
    }
  }
  @media (max-width: 360px){
    .row{
      width: 360px;
      min-width: 360px;
    }
    .container{
      overflow-x: auto;
    }
  }
}

.reviews_slider_section{
  .container{
    max-width: 980px;
  }
  .bg-effects-color{
    padding-top: 70px;
    padding-bottom: 80px;
    background-image: linear-gradient(90deg, rgba(9, 187, 251, 0.9) 0%, rgba(71, 139, 235, 0.9) 50%);
    width: calc(100% - 5rem);
  }
  .outter_wrap{
    width: 100%;
    background: url(/build/images/kini/back1.jpg) top center no-repeat;
    background-size: cover;
  }
  .slider_wrap{
    display: flex;
    justify-content: center;
    .slider{
      max-width: 678px;
    }
    .slide{
      .inside{
        .title{
          width: 100%;
          color: #ffffff;
          font-size: 30px;
          line-height: 31.99px;
          text-align: center;
          text-transform: uppercase;
          margin-bottom: 20px;
        }
        .stars{
          max-width: 332px;
          width: 100%;
          margin-bottom: 25px;
        }
        .text{
          width: 100%;
          margin-bottom: 0;
          color: #ffffff;
          font-family: Roboto;
          font-size: 18px;
          font-weight: 400;
          line-height: 130%;
          text-align: center;
        }
      }
    }
  }
  .arrow{
    position: absolute;
    bottom: 30px;
    z-index: 2;
  }
  .arrow_left{
    left: 0;
  }
  .arrow_right{
    right: 0;
  }
  @media (max-width: 991px){
    .arrow{
      bottom: initial;
      top: 68%;
      transform: translateY(-50%);
      img{
        width: 30px;
      }
    }
    .slider_wrap .slider{
      max-width: calc(100% - 80px);
    }
    .slider_wrap .slide .inside .stars{
      max-width: 246px;
    }
  }
  @media (max-width: 767px){
    .bg-effects-color{
      width: 95%;
    }
  }
  @media (max-width: 500px){
    .slider_wrap .slide .inside .text{
      font-size: 16px;
    }
    .slider_wrap .slider{
      width: calc(100% - 70px);
    }
  }
}

.maunawai_reviews_filter{
  padding-top: 75px;
  padding-bottom: 75px;
  h4,.h4{
    width: 100%;
    color: #666666;
    font-size: 26px;
    line-height: 130%;
    text-align: center;
    text-transform: uppercase;
    -webkit-text-fill-color:initial;
    margin-bottom: 20px;
  }
  .title{
    width: 100%;
    background: linear-gradient(90deg, #09bbfb, #478beb);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 45px;
    text-align: center;
    text-transform: uppercase;
    line-height: 120%;
    margin-bottom: 10px;
  }
  .stars{
    max-width: 276px;
    width: 100%;
    margin-bottom: 15px;
  }
  .rait_text{
    width: 100%;
    font-size: 28px;
    letter-spacing: normal;
    text-align: center;
    text-transform: uppercase;
    line-height: 120%;
    background: linear-gradient(90deg, #09bbfb, #478beb);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 25px;
  }
  .add_cart{
    max-width: 350px;
    text-align: center;
  }
  @media (max-width: 767px){
    .stars{
      max-width: 246px;
    }
  }
}

.also_known_area{
  padding-bottom: 115px;
  .container{
    max-width: 990px;
  }
  .row{
    border-top: 2px solid #b5b5b5;
    padding-top: 70px;
  }
  h4,.h4{
    width: 100%;
    color: #666666;
    font-size: 26px;
    line-height: 130%;
    text-align: center;
    text-transform: uppercase;
    -webkit-text-fill-color:initial;
    margin-bottom: 34px;
  }
  .row_item{
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0;
  }
  figure{
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 767px){
    padding-bottom: 60px;
    figure{
      width: 50%;
      margin-bottom: 60px;
    }
    .row_item{
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}

.filtration_result_section{
  padding-top: 66px;
  .plant_left{
    position: absolute;
    top: -178px;
    left: 0;
    z-index: 2;
  }
  .container{
    img{
      max-width: 384px;
      padding: 0;
    }
    p{
      color: #000000;
      font-family: Roboto;
      font-size: 24px;
      font-weight: 300;
      line-height: 130%;
      margin-bottom: 90px;
      margin-left: 41px;
      max-width: 604px;
      margin-right: 50px;
      span{
        color: #4285ea;
        font-weight: 500;
        font-family: Roboto;
      }
    }
  }
  @media (max-width: 1250px){
    .plant_left{
      width: 180px;
      left: -50px;
    }
  }
  @media (max-width: 1199px){
    .row{
      flex-wrap: nowrap;
      align-items: center !important;
    }
    .container p{
      margin-right: 0;
      margin-bottom: 0;
      font-size: 20px;
      max-width: calc(100% - 300px);
      padding-left: 30px;
      margin-left: 0;
    }
    .container img{
      max-width: 300px;
    }
  }
  @media (max-width: 767px){
    .row{
      flex-direction: column;
      align-items: center;
      img{
        order: 2;
      }
    }
    .container p{
      max-width: 100%;
      padding-left: 0;
      margin-bottom: 30px;
      text-align: center;
      font-size: 18px;
    }
    .plant_left{
      display: none;
    }
  }
}

.maunawai_premium_filter_wrap{
  z-index: 3;
  .back{
    width: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    object-fit: cover;
    min-height: 455px;
    object-position: center;
  }
  .back_mob{
    display: none;
  }
  .container{
    max-width: 80%;
    position: relative;
    z-index: 2;
  }
  .text_cont{
    background-color: #1aacf7;
    background-image: linear-gradient(90deg, rgb(9, 187, 251) 20%, rgb(71, 139, 235) 100%);
    padding: 123px 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 517px;
    p{
      color: #ffffff;
      font-family: Roboto;
      font-size: 24px;
      font-weight: 400;
      text-align: center;
      line-height: 140%;
      margin-top: 45px;
      margin-bottom: 45px;
    }
    img{
      width: 32px;
      padding: 0;
    }
  }
  @media (max-width: 991px){
    .container{
      max-width: 100%;
    }
    .text_cont{
      padding: 90px 50px;
      max-width: 50%;
      p{
        font-size: 20px;
      }
    }
  }
  @media (max-width: 767px){
    .container{
      padding: 0 !important;
    }
    .back{
      position: static;
      height: auto;
      display: none;
    }
    .back_mob{
      display: block;
    }
    .text_cont{
      max-width: 100%;
      padding: 50px 40px;
      p{
        margin-top: 30px;
        margin-bottom: 30px;
      }
    }
  }
}

.best_form_water{
  padding-top: 90px;
  padding-bottom: 72px;
  .container{
    max-width: 1440px;
    .row{
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
  .left_cont{
    width: 100%;
    max-width: 496px;
    padding-top: 55px;
    .title{
      width: 100%;
      color: #4285ea;
      font-family: Anton;
      font-size: 62px;
      text-align: left;
      text-transform: uppercase;
      line-height: 120%;
      margin-left: 167px;
      margin-bottom: 50px;
    }
    .filter{
      max-width: 100%;
    }
  }
  .right_cont{
    width: calc(100% - 496px);
    padding-left: 45px;
    padding-right: 164px;
    position: relative;
    .title{
      width: 100%;
      color: #4285ea;
      font-size: 32px;
      text-transform: uppercase;
      line-height: 120%;
      margin-bottom: 15px;
      position: relative;
      z-index: 2;
    }
    .wrap{
      width: 100%;
      border: 2px solid #fff;
      position: relative;
      z-index: 2;
      .row_item{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        border-bottom: 2px solid #fff;
        &:last-child{
          border-bottom: none;
        }
        .title_item{
          margin: 0;
          width: 100%;
          max-width: 243px;
          border-right: 2px solid #fff;
          color: #ffffff;
          font-size: 20px;
          font-weight: 400;
          line-height: 120%;
          text-transform: uppercase;
          background-image: linear-gradient(90deg, rgb(9, 187, 251) 20%, rgb(71, 139, 235) 100%);
          display: inline-flex;
          justify-content: flex-start;
          align-items: center;
          padding: 22px 18px;
        }
        .text_item{
          margin: 0;
          width: calc(100% - 243px);
          padding: 22px 18px;
          color: #000000;
          font-family: Roboto;
          font-size: 16px;
          font-weight: 300;
          line-height: 130%;
          background-color: #edf0f4;
          padding-right: 30px;
          display: inline-flex;
          justify-content: flex-start;
          align-items: center;
          a{
            color: #000;
            font-size: 15px;
            font-weight: 300;
            font-family: Roboto;
            text-decoration: underline !important;
          }
        }
      }
    }
    .plant_right{
      position: absolute;
      max-width: 114px;
      right: 51px;
      top: 165px;
      z-index: 1;
    }
  }
  @media (max-width: 1199px){
    .left_cont{
      max-width: 400px;
    }
    .right_cont{
      width: calc(100% - 400px);
      padding-right: 100px;
    }
    .left_cont .title{
      margin-left: 90px;
    }
  }
  @media (max-width: 1050px){
    .right_cont{
      padding-right: 40px;
    }
  }
  @media (max-width: 991px){
    padding-top: 80px;
    .container .row{
      flex-direction: column;
      align-items: center;
    }
    .container{
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .left_cont{
      padding-top: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-left: 15px;
      padding-right: 15px;
      .title{
        margin-left: 0;
        text-align: center;
        margin-bottom: 40px;
      }
      .filter{
        max-width: 250px;
      }
    }
    .right_cont{
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      max-width: 650px;
      margin-top: 25px;
      .title{
        text-align: center;
      }
    }
    .right_cont .wrap .row_item .title_item{
      font-size: 16px;
    }
  }
  @media (max-width: 767px){
    .container .row{
      margin-left: 0;
      margin-right: 0;
    }
    .right_cont .wrap .row_item .text_item{
      font-size: 12px;
      padding: 10px;
    }
    .right_cont .wrap .row_item .title_item{
      padding: 10px;
    }
    .right_cont .wrap{
      border: none;
    }
  }
  @media (max-width: 500px){
    .right_cont .wrap .row_item .title_item{
      max-width: 40%;
    }
    .right_cont .wrap .row_item .text_item{
      width: 60%;
    }
  }
}

@media (max-width: 991px){
  .container{
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
}


@media (min-width: 576px){
  .discount-modal .modal-body {
    width: 75%;
  }
}

@media (max-width:575px){
  .modal.discount-modal{
    padding-right: 0 !important;
  }
  .discount-modal .modal-content {
    background-image: url(https://res.cloudinary.com/maunawai/image/upload/f_auto,q_auto,w_576/promo/discount-bkg_cmelmd.jpg);
    background-size: cover;
  }
  .water_info_modal .modal-body .info .title{
    font-size: 20px;
  }
  .water_info_modal .modal-body .info .title_bottom{
    font-size: 22px;
  }
  .water_info_modal .modal-body .info .title {
    font-size: 20px;
    margin: 0 1.875rem 0.625rem;
  }
  .discount-modal .modal-body {
    width: 93%;
  }
}

@media (max-width:430px){
  .discount-modal .discount_modal_header{
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  }
  .discount-modal .discount_modal_header .discount_title{
    font-size: 30px;
  }
  .discount-modal .discount_modal_header .discount_title span{
    font-size: 60px;
  }
  .discount-modal .discount_modal_header .discount_title b{
    font-size: 35px;
  }
  .discount-modal .discount_modal_header .discount_title .percent_sup {
    top: 29px;
    right: -27px;
    font-size: 20px;
  }
  .discount-modal .discount_modal_header .discount_title .start_sup {
    font-size: 14px;
    top: 22px;
    right: -13px;
  }
  .discount-modal .discount_modal_header .discount_info{
    font-size: 18px;
    line-height: 22px;
  }
}

.kini_images,.kini_small_images{
  .main_photo{
    display: none;
  }
  .active{
    display: block;
  }
}
