@each $number, $value in $blues {
  .bg-blue-#{$number}{
    background-color: $value;
  }
  .text-blue-#{$number}{
    color: $value;
  }
}

@each $number, $value in $blue2s {
  .bg-blue2-#{$number}{
    background-color: $value;
  }
  .text-blue2-#{$number}{
    color: $value;
  }
}

@each $number, $value in $yellows {
  .bg-yellow-#{$number}{
    background-color: $value;
  }
  .text-yellow-#{$number}{
    color: $value;
  }
}

@each $number, $value in $limes {
  .bg-lime-#{$number}{
    background-color: $value;
  }
  .text-lime-#{$number}{
    color: $value;
  }
}


@each $number, $value in $greys {
  .bg-grey-#{$number}{
    background-color: $value;
  }
  .text-grey-#{$number}{
    color: $value;
  }
}