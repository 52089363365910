h1, h2, h3 {
  font-family: 'Anton', sans-serif;
}

.maunawai-font {
  font-family: 'Anton', sans-serif;
}

h1, h2 {
  color: $maunawai-dark-blue;
  text-transform: uppercase;

}
h2{
  letter-spacing: .5px;
}
h3 {
  color: $maunawai-green;
}
h5 {
color: $maunawai-black;
  text-transform: uppercase;
}

p, td {
  hyphens: auto;
  hyphenate-limit-chars: auto 5;
  hyphenate-limit-lines: 2;
  color: $maunawai-black;
}

.special-text {
  @include special-text($maunawai-yellow, $maunawai-green);
}

.special-text-blue {
  @include special-text($maunawai-light-blue, $maunawai-dark-blue);
}

.text-white-stroke {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
}

.text-maunawai-blue { color: $maunawai-dark-blue;}

.fa-layers-counter.cart {
  right: -0.25rem;
  transform: scale(0.6);
 }

.font-weight-bold {
  font-weight: bold;
}

.fs-xs {
  font-size: xx-small;
}

h1,.h1{
  font-size: 3.2rem;
  line-height: 3.6rem;
  color: #4386EA;
  background: linear-gradient(
90deg,#09bbfb,#478beb);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  @media (max-width: 1199px){
    font-size: 3rem;
    line-height: 100%;
  }
  @media (max-width: 768px){
    font-size: 2rem;
  }
}

.font-light {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
}

h2,.h2{
  font-size: 2.8rem;
  max-width: 700px;
  padding-bottom: 20px;
  border-bottom: 2px solid #c7c7c7;
  width: 100%;
  background: linear-gradient(
90deg,#09bbfb,#478beb);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  @media (max-width: 1199px){
    font-size: 2.2rem;
  }
  @media (max-width: 768px){
    font-size: 1.5rem;
  }
}

.small_logo_top{
  width: 54px;
}

h4,.h4{
  width: 100%;
  color: $maunawai-dark-blue;
  text-transform: uppercase;
  font-size: 1.9rem;
  background: linear-gradient(
90deg,#09bbfb,#478beb);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  @media (max-width: 1199px){
    font-size: 1.5rem;
  }
  @media (max-width: 768px){
    font-size: 1.2rem;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.btn-blue2{
  outline: none !important;
}