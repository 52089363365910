// @import "../../../../node_modules/bootstrap/scss/functions";
@import "helper/variables";
// @import "../../../../node_modules/bootstrap/scss/variables";

@import "helper/mixins";
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-select/dist/css/bootstrap-select.css";


@import "~slick-carousel/slick/slick-theme.scss";
@import "~slick-carousel/slick/slick.scss";
@import "layout/theme";
@import "layout/footer";
@import "layout/header";

@import "components/shape";
@import "components/buttons";
@import "components/type";
@import "layout/website";
@import "~video.js/src/css/video-js.scss";


[data-selector]:hover {
  cursor: pointer;
}

.nav-link {
  span, li {
    color: $maunawai-black
  }
}

.img-hover {

  .top {display: none;}
  .bottom {display: block;}
}
.img-hover img {

  transition: all 0.2s linear;
}

.img-hover:hover {
  .top {opacity: 1; display: block;}
  .bottom {opacity: 0; display: none;}
}

.cart-icon { color: $white; }

body.product-buy .product-holder {
  margin-top: 3rem;
}

.text-dark {
  color: #000 !important;
}