section.footer {
  font-size: $font-size-base * .8;
  h4 {color: $maunawai-black; font-size: $font-size-base}
  .nav-link {
    padding: 0.5rem 1rem 0.5rem 0;
    color: $grey-500;
    text-decoration: none;
    &:hover {
      color: $maunawai-dark-blue;
    }
  }
  .nav-item {line-height: 1;}
  .info { border-top: 1px solid #a0ccdf; border-bottom: 1px solid #a0ccdf;}
  background-color: #bfd8f0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top center;

  @include cloudinary_bg_img('web', 'footer-bg-xl_hjaymp.jpg', true);

  padding-top: 11rem;

  @include media-breakpoint-up(sm) {
    padding-top: 18rem;
  }
  @include media-breakpoint-up(md) {
    padding-top: 20rem;
  }
  @include media-breakpoint-up(lg) {

    padding-top: 32rem;
    background-size: cover;
  }
  @include media-breakpoint-up(xl) {
    padding-top: 38rem;

  }
  .bottom {
    @include gradient-x($blue-gradient-start, $blue-gradient-stop,0%, 100%);
    color: #fff;
    font-size: $font-size-base * .6;
  }
}

.footer-website{
    background: url("/build/images/home/footer-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 800px;
    margin-top: -400px;

    @include media-breakpoint-down(lg) {
      background: url("/build/images/home/footer-mobile-bg.png"), linear-gradient(to right, #e4eef8, 	#e7f0fb);
      background-repeat: no-repeat;
      background-size: auto;
      padding-top: 567px;
      margin-top: 0px;
    }

    @include media-breakpoint-up(4k) {
      padding-top: 400px;
    }

    .f-content-box{
      @include media-breakpoint-down(md) {
        padding-left: 1.875rem;
        padding-right: 1.875rem;
      }
    }

    .about-us{
      font-size: 1.125rem;
      color: 	hsl(0, 0%, 48%);
      white-space: nowrap;
      font-family: 'HelveticaNeue-Medium';
    }

    .title{
      font-size: 1.125rem;
      color: 	hsl(0, 0%, 48%);
      white-space: nowrap;
      font-family: 'HelveticaNeue-Medium';

      svg{
        display: none;
      }

      @include media-breakpoint-down(md) {
        padding: .6rem 2.6rem;
        background-image: linear-gradient(to right, #09bbfb 0%, #478beb 100%);
        color: white;
        border-bottom: 1px solid;
        &.first{
          border-top: 1px solid;
        }
        svg{
          display: block;
          float: right;
          font-size: 28px;
        }
        &.active {
          svg{
            transform: rotate(180deg );
          }
        }
      }
    }

    .mobile-logo{
      background-image: linear-gradient(to right, #09bbfb 0%, #478beb 100%);
      height: 50px;
      img{
        width: 100px;
      }
    }

    .items {
      font-family: 'HelveticaNeue-Light';

      @include media-breakpoint-down(md) {
        display: none;
        background-image: linear-gradient(to right, #09bbfb 0%, #478beb 100%);
        color: white;
        padding: 10px 0;
        a{
          color: white;
        }
        &.active{
          display: block;
        }
      }
    }

    p, a{
      font-family: 'HelveticaNeue-Light';
      color: 	hsl(0, 0%, 48%);
      font-weight: lighter;
      font-size: 0.875rem;
      text-decoration: none;
      display: block;
    }

    span, .copyright{
      font-size: 0.75rem;
      color: hsl(0, 0%, 48%);
    }

    input{
      border: 0px;
      height: 51px;
      font-size: 0.75rem;
      color: 	hsl(0, 0%, 48%);
      width: 250px;
      padding: 0px 10px;
      @media (max-width: 520px) {
        width: auto;
      }
    }

    .input-button{
      color: 	hsl(0, 0%, 48%);
      height: 46px;
      background: white;
      font-size: 0.875rem;
      vertical-align: middle;
      padding: 0px 20px;
    }

    .blue-border{
      @include media-breakpoint-up(md) {
        border-bottom: 1px solid hsl(200, 52%, 81%);
      }
    }

    .footer-bottom{
      background: linear-gradient(to right, 	hsl(196, 97%, 51%), 	hsl(215, 80%, 60%));
    }
    
  }