.header-container {
  height: 6em;
  color: #fff;
  z-index: 500;
  position: relative;
  .logo-img { border-radius: 50%; position: relative; z-index: 500;}
}

.header-small {
  height: auto;
  .logo-img { border-radius: 50%;max-height: 2rem; border: none;}
}

header.website{
	width: 100%;
	padding: 15px 0px;
	height: 57px;
	box-shadow: -15.893px 8.45px 24px 0px rgba(0,0,0,.2);
	&.header-transparent {
		position: absolute !important;
		background-image: none;
		box-shadow: none;
	}
	&.header-blue{
		a{
			font-family: "HelveticaNeue-Medium";
			font-size: 16px;
		}
	}
	a{
		text-decoration: none;
		text-transform: uppercase;
		font-size: 18px;
		position: relative;
		span.angle-down{
			top: 28px;
			font-size: 19px;
			right: 50%;
			width: 20px;
			height: 20px;
			color: #0bbafb;
			svg{
				vertical-align: initial;
			}
		}
	}
	.cart-number{
		width: 12px;
		height: 12px;
		border-radius: 100%;
		background: linear-gradient(to right, hsl(196, 97%, 51%), 	hsl(215, 80%, 60%));
		color: white;
		text-align: center;
		position: absolute;
		font-size: 8px;
		right: -3px;
		z-index: 2;
	}
	.logo {
		z-index: 10;
		img{
			width: 100px;
		}
		@include media-breakpoint-up(md) {
			bottom: -10px;
			img{
				width: 40px;
			}
		}
	}
}
