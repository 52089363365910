
//.btn-maunawai {
////button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))
//  @include button-variant($maunawai-dark-blue, $maunawai-dark-blue);
////  @include gradient-y($maunawai-light-blue, $maunawai-dark-blue,0%, 80%);
//  @include gradient-directional($blue-700, $blue-400, 35deg);
//  color: #fff;
//  text-transform: uppercase;
//  //border: 1px solid #fff;
//  @include app-box-shadow(1);
//  &:hover {
//    color: #fff;
//
//    //@include gradient-y($maunawai-light-blue, darken($maunawai-dark-blue,30%),0%, 80%);
//    @include app-box-shadow(2);
//  }
//}

.btn-link {
  color: $maunawai-black;
  text-decoration: none;
  &:hover {color: $maunawai-dark-blue;}
}


.btn-blue {
  @include button-variant($blue-700, $blue-500, $hover-background: $blue-800, $hover-border: $blue-500, $active-background: $blue-800, $active-border: $blue-600);
  text-transform: uppercase;
  @include gradient-directional($blue-700, $blue-400, 45deg);
  &:hover {
    @include gradient-directional($blue-800, $blue-500, 115deg);
    @include app-box-shadow(2);
  }
}


.btn-blue2, .btn-primary {
  color: #fff !important;
  @include button-variant($blue2-700, $blue2-400, $hover-background: $blue2-800, $hover-border: $blue2-600, $active-background: $blue2-900, $active-border: $blue-800);
  text-transform: uppercase;
  @include gradient-directional($blue2-700, $blue2-400, 45deg);
  //@include gradient-y($blue2-400, $blue2-700,0%, 50%);
  //box-shadow: inset 0 0.0625rem 0 hsl(224,84%, 74%), 0 0.0625rem 0.1875rem hsla(0, 0%, 0%, .2);
  padding: 10px 39px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  border-radius: 3px;
  font-size: 16px;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.2),0 1px 5px 0 rgba(0,0,0,.12);
  &:hover {
    //@include gradient-directional($blue2-400, $blue2-700, 180deg);

    @include gradient-directional($blue2-700, $blue2-500, 45deg);
    //@include app-box-shadow(2);
    box-shadow: 0 0 .5rem rgba(0,0,0,.18),0 .5rem 1rem rgba(0,0,0,.36) !important;
  }
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,{
    background-color: $blue-700;
    box-shadow: none;
  }
  figure{
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 50%;
    margin-left: 6px;
    img{
      width: 8px;
    }
  }
}

.btn-yellow {
  @include button-variant($yellow-700, $yellow-500, $hover-background: $yellow-800, $hover-border: $yellow-600, $active-background: $yellow-800, $active-border: $yellow-800);
  text-transform: uppercase;
  color: $yellow-900;
  @include gradient-directional($yellow-700, $yellow-400, 45deg);
  @include app-box-shadow(1);
  &:hover {
    color: $yellow-1000;
    @include gradient-directional($yellow-800, $yellow-600, 115deg);
    @include app-box-shadow(2);
  }
}


.btn-lime {
  @include button-variant($lime-700, $lime-500, $hover-background: $lime-800, $hover-border: $lime-600, $active-background: $lime-800, $active-border: $lime-800);
  text-transform: uppercase;
  color: $lime-900;
  @include app-box-shadow(1);
  @include gradient-directional($lime-700, $lime-400, 45deg);
  &:hover {
    color: $lime-1000;
    @include gradient-directional($lime-800, $lime-500, 115deg);
    @include app-box-shadow(2);
  }
}

.btn-gradient{
  border-image-slice: 1 !important;
  border-image-source: linear-gradient(to right, #48cafc, #5a95ed) !important;
}

.download-btn {
  display: inline-block;
  padding: 12px 40px;
  font-size: 18px;
  border-radius: 0;
  font-family: 'MyriadPro-Regular';
  outline: none;
  background-color: #68b16e;
  border: 2px solid #68b16e;
  color: #fff;
  transition: 0.3s;
  text-decoration: none;
  &:hover {
    background-color: #0bbafb;
    border-color: #0bbafb;
    color: white;
  }
  @include media-breakpoint-down(sm) {
    width: 100%;
    padding: 17px 20px;
  }
  @include media-breakpoint-down(lg) {
    width: 100%;
    line-height: 18px;
  }
  span{
    @include media-breakpoint-down(lg) {
      display: block;
    }
  }
  svg {
    font-size: 30px;
    vertical-align: sub;
    margin-right: 10px;
    width: 14px;
    @include media-breakpoint-down(lg) {
      float: left;
      margin-top: 6px;
    }
  }
  &:focus {
      outline: 0;
  }
  img{
    width: 100px;
    position: absolute;
    right: 0;
    bottom: 0%;
  }
}